import * as config from './config'
import axios from 'axios'

class InvalidConfigError extends Error {}

export const checkConfig = () => {
  const entries = [
    'baseApiURL',
  ]

  for (const entry of entries) {
    if (!config[entry]) {
      throw new InvalidConfigError(`No entry suplied in the API config: "${entry}"`)
    }
  }
}

export const initializeAxiosConfig = () => {
  checkConfig()
  axios.defaults.baseURL = config.baseApiURL

  axios.interceptors.response.use(
    res => ({
      ...res,
      ok: true,
    }),
    err => ({
      ...err,
      ok: false,
    }),
  )

  if (localStorage.getItem('token')) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }

  return axios
}
