export const FETCH_WAREHOUSE_OUTPUTS_REQUEST = 'FETCH_WAREHOUSE_OUTPUTS_REQUEST'
export const FETCH_WAREHOUSE_OUTPUTS_SUCCESS = 'FETCH_WAREHOUSE_OUTPUTS_SUCCESS'
export const FETCH_WAREHOUSE_OUTPUTS_FAILURE = 'FETCH_WAREHOUSE_OUTPUTS_FAILURE'

export const FETCH_WAREHOUSE_OUTPUTS_DETAIL_REQUEST = 'FETCH_WAREHOUSE_OUTPUTS_DETAIL_REQUEST'
export const FETCH_WAREHOUSE_OUTPUTS_DETAIL_SUCCESS = 'FETCH_WAREHOUSE_OUTPUTS_DETAIL_SUCCESS'
export const FETCH_WAREHOUSE_OUTPUTS_DETAIL_FAILURE = 'FETCH_WAREHOUSE_OUTPUTS_DETAIL_FAILURE'

export const CREATE_WAREHOUSE_OUTPUTS_REQUEST = 'CREATE_WAREHOUSE_OUTPUTS_REQUEST'
export const CREATE_WAREHOUSE_OUTPUTS_SUCCESS = 'CREATE_WAREHOUSE_OUTPUTS_SUCCESS'
export const CREATE_WAREHOUSE_OUTPUTS_FAILURE = 'CREATE_WAREHOUSE_OUTPUTS_FAILURE'

export const DELETE_WAREHOUSE_OUTPUTS_REQUEST = 'DELETE_WAREHOUSE_OUTPUTS_REQUEST'
export const DELETE_WAREHOUSE_OUTPUTS_SUCCESS = 'DELETE_WAREHOUSE_OUTPUTS_SUCCESS'
export const DELETE_WAREHOUSE_OUTPUTS_FAILURE = 'DELETE_WAREHOUSE_OUTPUTS_FAILURE'

export const ACTIVE_WAREHOUSE_OUTPUTS_REQUEST = 'ACTIVE_WAREHOUSE_OUTPUTS_REQUEST'
export const ACTIVE_WAREHOUSE_OUTPUTS_SUCCESS = 'ACTIVE_WAREHOUSE_OUTPUTS_SUCCESS'
export const ACTIVE_WAREHOUSE_OUTPUTS_FAILURE = 'ACTIVE_WAREHOUSE_OUTPUTS_FAILURE'

export const UPDATE_WAREHOUSE_OUTPUTS_REQUEST = 'UPDATE_WAREHOUSE_OUTPUTS_REQUEST'
export const UPDATE_WAREHOUSE_OUTPUTS_SUCCESS = 'UPDATE_WAREHOUSE_OUTPUTS_SUCCESS'
export const UPDATE_WAREHOUSE_OUTPUTS_FAILURE = 'UPDATE_WAREHOUSE_OUTPUTS_FAILURE'
