import * as types from '../mutations-types'
import {
  createClients,
  fetchClients,
  fetchAllClients,
  fetchClientsById,
  updateClient,
  deleteClient,
  activeClient,
  deactiveClient,
} from '../../api'

export default {
  async createClient ({ commit, dispatch }, form) {
    commit(types.CREATE_CLIENTS_REQUEST)
    const req = await createClients(form)
    !req.error
      ? commit(types.CREATE_CLIENTS_SUCCESS, req.data)
      : commit(types.CREATE_CLIENTS_FAILURE, req)

    dispatch('fetchAllClient')
  },

  async updateClients ({ commit, dispatch }, form) {
    commit(types.UPDATE_CLIENTS_REQUEST)
    const req = await updateClient(form)
    req.ok
      ? commit(types.UPDATE_CLIENTS_SUCCESS, req.data.user)
      : commit(types.UPDATE_CLIENTS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchAllClient')
  },

  async activeClients ({ commit, dispatch }, form) {
    commit(types.UPDATE_CLIENTS_REQUEST)
    const req = await activeClient(form)
    req.ok
      ? commit(types.UPDATE_CLIENTS_SUCCESS, req.data.user)
      : commit(types.UPDATE_CLIENTS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchAllClient')
  },

  async deactiveClients ({ commit, dispatch }, form) {
    commit(types.UPDATE_CLIENTS_REQUEST)
    const req = await deactiveClient(form)
    req.ok
      ? commit(types.UPDATE_CLIENTS_SUCCESS, req.data.user)
      : commit(types.UPDATE_CLIENTS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchAllClient')
  },

  async deleteClients ({ commit, dispatch }, form) {
    commit(types.DELETE_USER_REQUEST)
    const req = await deleteClient(form)
    req.ok
      ? commit(types.DELETE_USER_SUCCESS, form)
      : commit(types.DELETE_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchAllClient')
  },

  async fetchClient ({ commit }, form) {
    commit(types.FETCH_CLIENTS_REQUEST)
    const req = await fetchClients(form)
    !req.error
      ? commit(types.FETCH_CLIENTS_SUCCESS, req.data?.client)
      : commit(types.FETCH_CLIENTS_FAILURE, req)
  },

  async fetchAllClient ({ commit }, form) {
    commit(types.FETCH_CLIENTS_ALL_REQUEST)
    const req = await fetchAllClients(form)
    !req.error
      ? commit(types.FETCH_CLIENTS_ALL_SUCCESS, req.data)
      : commit(types.FETCH_CLIENTS_ALL_FAILURE, req)
  },

  async fetchClientById ({ commit }, form) {
    commit(types.FETCH_CLIENTS_REQUEST)
    const req = await fetchClientsById(form)
    !req.error
      ? commit(types.FETCH_CLIENTS_SUCCESS, req.data)
      : commit(types.FETCH_CLIENTS_FAILURE, req)
  },
}
