import * as types from '../mutations-types'
import {
  fetchWarehouseMovements,
  fetchWarehouseMovementsDetail,
  createWarhouseMovements,
  deleteWarhouseMovements,
  activeWarhouseMovements,
  updateWarhouseMovements,
} from '@/api'

export default {
  async fetchWarehouseMovements ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_MOVEMENTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('payMov', payload)
    const req = await fetchWarehouseMovements(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqMov', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_MOVEMENTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_MOVEMENTS_FAILURE, req)
  },

  async fetchWarehouseMovementsDetail ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarehouseMovementsDetail(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqMov', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_FAILURE, req)
  },

  async createWarehouseMovements ({ commit }, payload) {
    commit(types.CREATE_WAREHOUSE_MOVEMENTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('createMovement', payload)
    const req = await createWarhouseMovements(payload)
    req.ok
      ? commit(types.CREATE_WAREHOUSE_MOVEMENTS_SUCCESS)
      : commit(types.CREATE_WAREHOUSE_MOVEMENTS_FAILURE, req)
  },

  async deleteWarhouseMovements ({ commit }, { id }) {
    commit(types.DELETE_WAREHOUSE_MOVEMENTS_REQUEST)
    const req = await deleteWarhouseMovements(id)
    req.ok
      ? commit(types.DELETE_WAREHOUSE_MOVEMENTS_SUCCESS)
      : commit(types.DELETE_WAREHOUSE_MOVEMENTS_FAILURE, req)
  },

  async activeWarhouseMovements ({ commit }, { id }) {
    commit(types.ACTIVE_WAREHOUSE_MOVEMENTS_REQUEST)
    const req = await activeWarhouseMovements(id)
    req.ok
      ? commit(types.ACTIVE_WAREHOUSE_MOVEMENTS_SUCCESS)
      : commit(types.ACTIVE_WAREHOUSE_MOVEMENTS_FAILURE, req)
  },

  async updateWarhouseMovements ({ commit }, payload) {
    commit(types.UPDATE_WAREHOUSE_MOVEMENTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('update', payload)
    const req = await updateWarhouseMovements(payload)
    req.ok
      ? commit(types.UPDATE_WAREHOUSE_MOVEMENTS_SUCCESS)
      : commit(types.UPDATE_WAREHOUSE_MOVEMENTS_FAILURE, req)
  },
}
