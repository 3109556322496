<template>
  <v-snackbar
    v-model="opened"
    color="red"
    top
    right
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'ErrorSnackbar',
    data () {
      return {
        opened: false,
        message: null,
      }
    },
    computed: {
      ...mapState([
        'error',
      ]),
    },
    watch: {
      error (error) {
        if (error !== null) {
          this.openErrorSnackbar(error)
          setTimeout(this.closeErrorSnackbar, 7000)
        }
      },
    },
    methods: {
      ...mapActions([
        'setError',
      ]),
      openErrorSnackbar (message) {
        this.opened = true
        this.message = message
      },
      closeErrorSnackbar () {
        this.opened = false
        this.message = null
        this.setError(null)
      },
    },
  }
</script>
