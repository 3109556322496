export const FETCH_WAREHOUSE_INPUTS_REQUEST = 'FETCH_WAREHOUSE_INPUTS_REQUEST'
export const FETCH_WAREHOUSE_INPUTS_SUCCESS = 'FETCH_WAREHOUSE_INPUTS_SUCCESS'
export const FETCH_WAREHOUSE_INPUTS_FAILURE = 'FETCH_WAREHOUSE_INPUTS_FAILURE'

export const FETCH_WAREHOUSE_INPUTS_DETAIL_REQUEST = 'FETCH_WAREHOUSE_INPUTS_DETAIL_REQUEST'
export const FETCH_WAREHOUSE_INPUTS_DETAIL_SUCCESS = 'FETCH_WAREHOUSE_INPUTS_DETAIL_SUCCESS'
export const FETCH_WAREHOUSE_INPUTS_DETAIL_FAILURE = 'FETCH_WAREHOUSE_INPUTS_DETAIL_FAILURE'

export const CREATE_WAREHOUSE_INPUTS_REQUEST = 'CREATE_WAREHOUSE_INPUTS_REQUEST'
export const CREATE_WAREHOUSE_INPUTS_SUCCESS = 'CREATE_WAREHOUSE_INPUTS_SUCCESS'
export const CREATE_WAREHOUSE_INPUTS_FAILURE = 'CREATE_WAREHOUSE_INPUTS_FAILURE'

export const DELETE_WAREHOUSE_INPUTS_REQUEST = 'DELETE_WAREHOUSE_INPUTS_REQUEST'
export const DELETE_WAREHOUSE_INPUTS_SUCCESS = 'DELETE_WAREHOUSE_INPUTS_SUCCESS'
export const DELETE_WAREHOUSE_INPUTS_FAILURE = 'DELETE_WAREHOUSE_INPUTS_FAILURE'

export const ACTIVE_WAREHOUSE_INPUTS_REQUEST = 'ACTIVE_WAREHOUSE_INPUTS_REQUEST'
export const ACTIVE_WAREHOUSE_INPUTS_SUCCESS = 'ACTIVE_WAREHOUSE_INPUTS_SUCCESS'
export const ACTIVE_WAREHOUSE_INPUTS_FAILURE = 'ACTIVE_WAREHOUSE_INPUTS_FAILURE'

export const UPDATE_WAREHOUSE_INPUTS_REQUEST = 'UPDATE_WAREHOUSE_INPUTS_REQUEST'
export const UPDATE_WAREHOUSE_INPUTS_SUCCESS = 'UPDATE_WAREHOUSE_INPUTS_SUCCESS'
export const UPDATE_WAREHOUSE_INPUTS_FAILURE = 'UPDATE_WAREHOUSE_INPUTS_FAILURE'
