import * as types from '../mutations-types/index'

export default {
  [types.CREATE_CLIENTS_REQUEST] (state) {
    state.fecthingClients = true
  },
  [types.CREATE_CLIENTS_SUCCESS] (state, data) {
    state.createClientResponse = data
    state.success = 'Cliente creado con exito!'
    state.fecthingClients = false
  },
  [types.CREATE_CLIENTS_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fecthingClients = false
  },

  [types.FETCH_CLIENTS_REQUEST] (state) {
    state.fecthingClients = true
  },
  [types.FETCH_CLIENTS_SUCCESS] (state, data) {
    state.clientsSearch = data
    state.fecthingClients = false
  },
  [types.FETCH_CLIENTS_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fecthingClients = false
  },

  [types.FETCH_CLIENTS_ALL_REQUEST] (state) {
    state.fecthingClients = true
  },
  [types.FETCH_CLIENTS_ALL_SUCCESS] (state, data) {
    state.clients = data
    state.fecthingClients = false
  },
  [types.FETCH_CLIENTS_ALL_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fecthingClients = false
  },

  [types.UPDATE_CLIENTS_REQUEST] (state) {
    state.fecthingClients = true
  },

  [types.UPDATE_CLIENTS_SUCCESS] (state, user) {
    state.success = 'Se ha actualizado el cliente satisfactoriamente'
    state.fecthingClients = false
  },

  [types.UPDATE_CLIENTS_FAILURE] (state, { error, message }) {
    state.error = message
    state.fecthingClients = false
  },

  [types.DELETE_CLIENTS_REQUEST] (state) {
    state.fecthingClients = true
  },

  [types.DELETE_CLIENTS_SUCCESS] (state, user) {
    state.success = 'Se ha eliminado el cliente satisfactoriamente'
    state.fecthingClients = false
  },

  [types.DELETE_CLIENTS_FAILURE] (state, { error, message }) {
    state.error = message
    state.fecthingClients = false
  },
}
