import axios from 'axios'

export const fetchParentChilds = async parentId => {
  return await axios.get(`/api/v1/business/parents/child/${parentId}`)
}

export const createBusiness = async data => {
  return await axios.post('/api/v1/business', data)
}

export const deactiveBusiness = async id => {
  return await axios.delete(`/api/v1/business/deactive/${id}`)
}

export const updateBusiness = async (id, data) => {
  return await axios.put(`/api/v1/business/${id}`, {
    ...data,
    status: true,
  })
}
