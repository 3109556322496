import * as types from '../mutations-types'
import {
  fetchProductClassification,
  createProductClassification,
  deleteProductClassification,
  activeProductClassification,
  updateProductClassification,
} from '@/api'

export default {
  async fetchProductClassification ({ commit }, payload) {
    commit(types.FETCH_PRODUCT_CLASSIFICATION_REQUEST)
    const req = await fetchProductClassification(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_PRODUCT_CLASSIFICATION_SUCCESS, req.data.productClassifications)
      : commit(types.FETCH_PRODUCT_CLASSIFICATION_FAILURE, req)
  },

  async createProductClassification ({ commit }, payload) {
    commit(types.CREATE_PRODUCT_CLASSIFICATION_REQUEST)
    const req = await createProductClassification(payload)
    req.ok
      ? commit(types.CREATE_PRODUCT_CLASSIFICATION_SUCCESS)
      : commit(types.CREATE_PRODUCT_CLASSIFICATION_FAILURE, req)
  },

  async deleteProductClassification ({ commit }, { id }) {
    commit(types.DELETE_PRODUCT_CLASSIFICATION_REQUEST)
    const req = await deleteProductClassification(id)
    req.ok
      ? commit(types.DELETE_PRODUCT_CLASSIFICATION_SUCCESS)
      : commit(types.DELETE_PRODUCT_CLASSIFICATION_FAILURE, req)
  },

  async activeProductClassification ({ commit }, { id }) {
    commit(types.ACTIVE_PRODUCT_CLASSIFICATION_REQUEST)
    const req = await activeProductClassification(id)
    req.ok
      ? commit(types.ACTIVE_PRODUCT_CLASSIFICATION_SUCCESS)
      : commit(types.ACTIVE_PRODUCT_CLASSIFICATION_FAILURE, req)
  },

  async updateProductClassification ({ commit }, payload) {
    commit(types.UPDATE_PRODUCT_CLASSIFICATION_REQUEST)
    const req = await updateProductClassification(payload)
    req.ok
      ? commit(types.UPDATE_PRODUCT_CLASSIFICATION_SUCCESS)
      : commit(types.UPDATE_PRODUCT_CLASSIFICATION_FAILURE, req)
  },
}
