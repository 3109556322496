import * as types from '../mutations-types/index.js'

export default {
  [types.FETCH_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.FETCH_USER_SUCCESS] (state, user) {
    state.user = user
    state.fetchingUser = false
  },

  [types.FETCH_USER_FAILURE] (state, { error, message }) {
    state.error = message
    state.fetchingUser = false
  },

  [types.FETCH_USER_BY_SUCURSAL_ID_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.FETCH_USER_BY_SUCURSAL_ID_SUCCESS] (state, users) {
    state.userBySucursalId = users
    state.fetchingUser = false
  },

  [types.FETCH_USER_BY_SUCURSAL_ID_FAILURE] (state, { error, message }) {
    state.error = message
    state.fetchingUser = false
  },

  [types.SET_USER] (state, user) {
    state.user = user
  },

  [types.UPDATE_PROFILE_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.UPDATE_PROFILE_USER_SUCCESS] (state, { name, lastName }) {
    state.fetchingUser = false
    state.user.name = name
    state.user.lastName = lastName
    state.success = 'Actualizado con exito'
  },

  [types.UPDATE_PROFILE_USER_FAILURE] (state, { error, message }) {
    state.error = message
    state.fetchingUser = false
  },

  [types.ROLES_USERS_REQUEST] (state) {
    state.fetchingRoles = true
  },

  [types.ROLES_USERS_SUCCESS] (state, data) {
    state.roles = data.roles
    state.fetchingRoles = false
  },

  [types.ROLES_USERS_FAILURE] (state, { error, message }) {
    state.error = message
    state.fetchingRoles = false
  },

  [types.FETCH_USERS_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.FETCH_USERS_SUCCESS] (state, users) {
    state.users = users
    state.fetchingUser = false
  },

  [types.FETCH_USERS_FAILURE] (state, { error, message }) {
    state.error = message
    state.fetchingUser = false
  },

  [types.SET_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.SET_USER_SUCCESS] (state, user) {
    // state.users = [...state.users, user]
    state.createUserResponse = user
    state.success = 'Se ha registrado el usuario satisfactoriamente'
    state.fetchingUser = false
  },

  [types.SET_USER_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },

  [types.UPDATE_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.UPDATE_USER_SUCCESS] (state, user) {
    state.success = 'Se ha actualizado el usuario satisfactoriamente'
    state.fetchingUser = false
  },

  [types.UPDATE_USER_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },

  [types.DELETE_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.DELETE_USER_SUCCESS] (state, user) {
    state.success = 'Se ha eliminado el usuario satisfactoriamente'
    state.fetchingUser = false
  },

  [types.DELETE_USER_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },

  [types.ENABLE_USER_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.ENABLE_USER_SUCCESS] (state, user) {
    state.success = 'Se ha habilitado el usuario satisfactoriamente'
    state.fetchingUser = false
  },

  [types.ENABLE_USER_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },

  [types.SET_AUTHORIZATIONS_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.SET_AUTHORIZATIONS_SUCCESS] (state, user) {
    // state.users = [...state.users, user]
    // state.createUserResponse = user
    state.success = 'Se han agregado los roles satisfactoriamente'
    state.fetchingUser = false
  },

  [types.SET_AUTHORIZATIONS_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },

  [types.UNSET_AUTHORIZATIONS_REQUEST] (state) {
    state.fetchingUser = true
  },

  [types.UNSET_AUTHORIZATIONS_SUCCESS] (state, user) {
    // state.users = [...state.users, user]
    // state.createUserResponse = user
    state.success = 'Se ha revocado el rol satisfactoriamente'
    state.fetchingUser = false
  },

  [types.UNSET_AUTHORIZATIONS_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingUser = false
  },
}
