import * as types from '../mutations-types/index'
import { setPassword } from '../../api'

export default {
  async setPassword ({ commit }, form) {
    commit(types.SET_PASSWORD_REQUEST)
    const req = await setPassword(form)
    req.ok
      ? commit(types.SET_PASSWORD_SUCCESS, req.data)
      : commit(types.SET_PASSWORD_FAILURE, req)
  },
}
