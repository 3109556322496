import * as types from '../mutations-types'

export default {
  [types.FETCH_WAREHOUSE_INVENTORY_REQUEST] (state) {
    state.fechingWarehouseInventory = true
  },

  [types.FETCH_WAREHOUSE_INVENTORY_SUCCESS] (state, warehousesInventory) {
    state.warehousesInventory = warehousesInventory
    state.fechingWarehouseInventory = false
  },

  [types.FETCH_WAREHOUSE_INVENTORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fechingWarehouseInventory = false
  },

  [types.FETCH_STORE_INVENTORY_REQUEST] (state) {
    state.fechingStoreInventory = true
  },

  [types.FETCH_STORE_INVENTORY_SUCCESS] (state, storeInventory) {
    state.storeInventory = storeInventory
    state.fechingStoreInventory = false
  },

  [types.FETCH_STORE_INVENTORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fechingStoreInventory = false
  },
}
