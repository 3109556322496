import {
  fetchCurrencies,
  updateCurrencies,
} from '../../api'
import * as types from '../mutations-types/index.js'

export default {
  async getCurrencies ({ commit }, payload) {
    commit(types.FETCH_CURRENCIES_REQUEST)
    const req = await fetchCurrencies(payload)
    req.ok
      ? commit(types.FETCH_CURRENCIES_SUCCESS, req.data.currencies)
      : commit(types.FETCH_CURRENCIES_FAILURE, { message: 'Código de error: ', error: req })
  },

  async updateCurrencies ({ commit }, payload) {
    commit(types.UPDATE_CURRENCIES_REQUEST)
    const req = await updateCurrencies(payload)
    req.ok
      ? commit(types.UPDATE_CURRENCIES_SUCCESS)
      : commit(types.UPDATE_CURRENCIES_FAILURE, { message: 'Código de error: ', error: req })
  },

}
