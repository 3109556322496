import * as types from '../mutations-types'

export default {
  [types.FETCH_WAREHOUSE_OUTPUTS_REQUEST] (state) {
    state.fetchingWarehouseOutputs = true
  },

  [types.FETCH_WAREHOUSE_OUTPUTS_SUCCESS] (state, warhouseOutputs) {
    state.warhouseOutputs = warhouseOutputs
    state.fetchingWarehouseOutputs = false
  },

  [types.FETCH_WAREHOUSE_OUTPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseOutputs = false
  },

  [types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_REQUEST] (state) {
    state.fetchingWarehouseOutputsDetail = true
  },

  [types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_SUCCESS] (state, warhouseOutputsDeatil) {
    state.warhouseOutputsDetail = warhouseOutputsDeatil
    state.fetchingWarehouseOutputsDetail = false
  },

  [types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_FAILURE] (state, error) {
    // console.error(error)
    state.error = error.response.data.msg || 'Error inesperado'
    state.fetchingWarehouseOutputsDetail = false
  },

  [types.CREATE_WAREHOUSE_OUTPUTS_REQUEST] (state) {
    state.fetchingWarehouseOutputs = true
  },

  [types.CREATE_WAREHOUSE_OUTPUTS_SUCCESS] (state) {
    state.success = 'Se ha generado la salida con exito'
    state.fetchingWarehouseOutputs = false
  },

  [types.CREATE_WAREHOUSE_OUTPUTS_FAILURE] (state, error) {
    // console.error(error)
    state.error = error.response?.data?.msg || 'Error inesperado'
    state.fetchingWarehouseOutputs = false
  },

  [types.DELETE_WAREHOUSE_OUTPUTS_REQUEST] (state) {
    state.fetchingWarehouseOutputs = true
  },

  [types.DELETE_WAREHOUSE_OUTPUTS_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el producto'
    state.fetchingWarehouseOutputs = false
  },

  [types.DELETE_WAREHOUSE_OUTPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseOutputs = false
  },

  [types.ACTIVE_WAREHOUSE_OUTPUTS_REQUEST] (state) {
    state.fetchingWarehouseOutputs = true
  },

  [types.ACTIVE_WAREHOUSE_OUTPUTS_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el producto'
    state.fetchingWarehouseOutputs = false
  },

  [types.ACTIVE_WAREHOUSE_OUTPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseOutputs = false
  },

  [types.UPDATE_WAREHOUSE_OUTPUTS_REQUEST] (state) {
    state.fetchingWarehouseOutputs = true
  },

  [types.UPDATE_WAREHOUSE_OUTPUTS_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingWarehouseOutputs = false
  },

  [types.UPDATE_WAREHOUSE_OUTPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseOutputs = false
  },
}
