import * as types from '../mutations-types'
import {
  fetchPaymentsMethods,
} from '@/api'

export default {
  async fetchPaymentsMethods ({ commit }) {
    commit(types.FETCH_PAYMENTS_METHODS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchPaymentsMethods()
    // // // // // // // // // // // // // // // // // // // // // // // console.log('PM', req)
    req.ok
      ? commit(types.FETCH_PAYMENTS_METHODS_SUCCESS, req.data.paymentMethods)
      : commit(types.FETCH_PAYMENTS_METHODS_FAILURE, req)
  },
}
