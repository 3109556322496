import * as types from '../mutations-types'
import {
  fetchWarhouseInputs,
  fetchWarehouseSalesReturns,
  fetchWarhouseInputsDetail,
  createWarhouseInputs,
  deleteWarhouseInputs,
  activeWarhouseInputs,
  updateWarhouseInputs,
} from '@/api'

export default {
  async fetchWarhouseInputs ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_INPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarhouseInputs(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqIn', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_INPUTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_INPUTS_FAILURE, req)
  },

  async fetchWarehouseSalesReturns ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_INPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarehouseSalesReturns(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqIn', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_INPUTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_INPUTS_FAILURE, req)
  },

  async fetchWarhouseInputsDetail ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_INPUTS_DETAIL_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarhouseInputsDetail(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqInD', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_INPUTS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_INPUTS_DETAIL_FAILURE, req)
  },

  async createWarehouseInputs ({ commit }, payload) {
    commit(types.CREATE_WAREHOUSE_INPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('createInput', payload)
    const req = await createWarhouseInputs(payload)
    req.ok
      ? commit(types.CREATE_WAREHOUSE_INPUTS_SUCCESS)
      : commit(types.CREATE_WAREHOUSE_INPUTS_FAILURE, req)
  },

  async deleteWarhouseInputs ({ commit }, { id }) {
    commit(types.DELETE_WAREHOUSE_INPUTS_REQUEST)
    const req = await deleteWarhouseInputs(id)
    req.ok
      ? commit(types.DELETE_WAREHOUSE_INPUTS_SUCCESS)
      : commit(types.DELETE_WAREHOUSE_INPUTS_FAILURE, req)
  },

  async activeWarhouseInputs ({ commit }, { id }) {
    commit(types.ACTIVE_WAREHOUSE_INPUTS_REQUEST)
    const req = await activeWarhouseInputs(id)
    req.ok
      ? commit(types.ACTIVE_WAREHOUSE_INPUTS_SUCCESS)
      : commit(types.ACTIVE_WAREHOUSE_INPUTS_FAILURE, req)
  },

  async updateWarhouseInputs ({ commit }, payload) {
    commit(types.UPDATE_WAREHOUSE_INPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('update', payload)
    const req = await updateWarhouseInputs(payload)
    req.ok
      ? commit(types.UPDATE_WAREHOUSE_INPUTS_SUCCESS)
      : commit(types.UPDATE_WAREHOUSE_INPUTS_FAILURE, req)
  },
}
