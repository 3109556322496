export const FETCH_PRODUCT_TYPE_REQUEST = 'FETCH_PRODUCT_TYPE_REQUEST'
export const FETCH_PRODUCT_TYPE_SUCCESS = 'FETCH_PRODUCT_TYPE_SUCCESS'
export const FETCH_PRODUCT_TYPE_FAILURE = 'FETCH_PRODUCT_TYPE_FAILURE'

export const CREATE_PRODUCT_TYPE_REQUEST = 'CREATE_PRODUCT_TYPE_REQUEST'
export const CREATE_PRODUCT_TYPE_SUCCESS = 'CREATE_PRODUCT_TYPE_SUCCESS'
export const CREATE_PRODUCT_TYPE_FAILURE = 'CREATE_PRODUCT_TYPE_FAILURE'

export const DELETE_PRODUCT_TYPE_REQUEST = 'DELETE_PRODUCT_TYPE_REQUEST'
export const DELETE_PRODUCT_TYPE_SUCCESS = 'DELETE_PRODUCT_TYPE_SUCCESS'
export const DELETE_PRODUCT_TYPE_FAILURE = 'DELETE_PRODUCT_TYPE_FAILURE'

export const ACTIVE_PRODUCT_TYPE_REQUEST = 'ACTIVE_PRODUCT_TYPE_REQUEST'
export const ACTIVE_PRODUCT_TYPE_SUCCESS = 'ACTIVE_PRODUCT_TYPE_SUCCESS'
export const ACTIVE_PRODUCT_TYPE_FAILURE = 'ACTIVE_PRODUCT_TYPE_FAILURE'

export const UPDATE_PRODUCT_TYPE_REQUEST = 'UPDATE_PRODUCT_TYPE_REQUEST'
export const UPDATE_PRODUCT_TYPE_SUCCESS = 'UPDATE_PRODUCT_TYPE_SUCCESS'
export const UPDATE_PRODUCT_TYPE_FAILURE = 'UPDATE_PRODUCT_TYPE_FAILURE'
