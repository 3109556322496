import * as types from '../mutations-types'
import { fetchWelcome } from '../../api'

export default {
  async fetchWelcome ({ commit }) {
    commit(types.FETCH_WELCOME_REQUEST)
    const req = await fetchWelcome()
    req.ok
      ? commit(types.FETCH_WELCOME_SUCCESS, req.data)
      : commit(types.FETCH_WELCOME_FAILURE, 'Error at try to get the welcome from the API')
  },
}
