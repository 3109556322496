import * as types from '../mutations-types'

export default {
  [types.FETCH_PRODUCT_LINE_REQUEST] (state) {
    state.fetchingProductLine = true
  },

  [types.FETCH_PRODUCT_LINE_SUCCESS] (state, productLine) {
    state.productLine = productLine
    state.fetchingProductLine = false
  },

  [types.FETCH_PRODUCT_LINE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingProductLine = false
  },

  [types.CREATE_PRODUCT_LINE_REQUEST] (state) {
    state.fetchingProductLine = true
  },

  [types.CREATE_PRODUCT_LINE_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito la linea de productos'
    state.fetchingProductLine = false
  },

  [types.CREATE_PRODUCT_LINE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductLine = false
  },

  [types.DELETE_PRODUCT_LINE_REQUEST] (state) {
    state.fetchingProductLine = true
  },

  [types.DELETE_PRODUCT_LINE_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente la linea de producto'
    state.fetchingProductLine = false
  },

  [types.DELETE_PRODUCT_LINE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductLine = false
  },

  [types.ACTIVE_PRODUCT_LINE_REQUEST] (state) {
    state.fetchingProductLine = true
  },

  [types.ACTIVE_PRODUCT_LINE_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente la linea de producto'
    state.fetchingProductLine = false
  },

  [types.ACTIVE_PRODUCT_LINE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductLine = false
  },

  [types.UPDATE_PRODUCT_LINE_REQUEST] (state) {
    state.fetchingProductLine = true
  },

  [types.UPDATE_PRODUCT_LINE_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito la linea de producto'
    state.fetchingProductLine = false
  },

  [types.UPDATE_PRODUCT_LINE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductLine = false
  },
}
