export const FETCH_PARENT_CHILDS_REQUEST = 'FETCH_PARENT_CHILDS_REQUEST'
export const FETCH_PARENT_CHILDS_SUCCESS = 'FETCH_PARENT_CHILDS_SUCCESS'
export const FETCH_PARENT_CHILDS_FAILURE = 'FETCH_PARENT_CHILDS_FAILURE'

export const CREATE_BUSINESS_REQUEST = 'CREATE_BUSINESS_REQUEST'
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS'
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE'

export const DEACTIVE_BUSINESS_REQUEST = 'DEACTIVE_BUSINESS_REQUEST'
export const DEACTIVE_BUSINESS_SUCCESS = 'DEACTIVE_BUSINESS_SUCCESS'
export const DEACTIVE_BUSINESS_FAILURE = 'DEACTIVE_BUSINESS_FAILURE'

export const UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST'
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE'

export const UPDATE_ACCESS = 'UPDATE_ACCESS'
