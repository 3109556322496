import axios from 'axios'
import { DateTime } from 'luxon'
import { TYPE_TRANSACTION } from '../../const'

export const fetchWarehousesByParentId = async ({ id }) => {
  return await axios.get('/api/v1/warehouses/getBusinessId/' + id)
}

export const fetchWarehousesById = async ({ id }) => {
  return await axios.get('/api/v1/warehouses/getById/' + id)
}

export const fetchStoreWarehouses = async () => {
  return await axios.get('/api/v1/warehouses/getStoreWarehouse')
}

export const assingWarehouse = async (form) => {
  const data = {
    businessId: form.parentBusiness,
    userId: form.userId,
    warehouseId: form.idBusiness,
    roleId: form.idRole,
  }
  // // // // // // // // // // // // // // // // // // // // // // // // // console.log('wareData', data)
  return await axios.post('/api/v1/authorizations/assignUserWarehouse', data)
}

export const getBusinessByWarehousesId = async (id) => {
  // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return await axios.get('/api/v1/warehouses/getBusinessByWarehouseId/' + id)
}

export const createWarehouses = async (data) => {
  return axios.post('/api/v1/warehouses/', data)
}

export const assingWarehouseParentBusiness = async (data) => {
  return axios.post('/api/v1/warehouses/createWarehousesBusiness', data)
}

export const updateWarehouse = async (data) => {
  return axios.put('/api/v1/warehouses/' + data.id, data)
}

export const deleteWarehouse = async (id) => {
  return axios.post('/api/v1/warehouses/deactive/' + id)
}

export const activeWarehouse = async (id) => {
  return axios.post('/api/v1/warehouses/active/' + id)
}

export const unsetWarehouse = async id => {
  return await axios.delete('/api/v1/authorizations/deleteWarehouse/' + id)
}

export const getWarehousesInputChartData = async (warehouseId) => {
  return await axios.get('/api/v1/transactions/countByFilter', {
    params: {
      warehouseId: Number(warehouseId),
      approved: 1,
      typeTransactionId: TYPE_TRANSACTION.entrada,
      start: DateTime.now().minus({ days: 6 }).toFormat(
        'yyyy-MM-dd',
      ),
    },
  })
}

export const getWarehousesOutputChartData = async (warehouseId) => {
  return await axios.get('/api/v1/transactions/countByFilter', {
    params: {
      warehouseId,
      approved: 1,
      typeTransactionId: TYPE_TRANSACTION.salida,
      start: DateTime.now().minus({ days: 6 }).toFormat(
        'yyyy-MM-dd',
      ),
    },
  })
}

export const getWarehousesConcessionedChartData = async (warehouseId) => {
  return await axios.get('/api/v1/transactions/countByFilter', {
    params: {
      warehouseId,
      approved: 1,
      typeTransactionId: TYPE_TRANSACTION.concesion,
      start: DateTime.now().minus({ days: 6 }).toFormat(
        'yyyy-MM-dd',
      ),
    },
  })
}
