import axios from 'axios'

const MODULE_BASE = '/api/v1/warehouses-movements-bulk-load'

export const wmBulkLoadCheckFile = async (formData) => {
  return await axios.post(`${MODULE_BASE}/check`, formData)
}

export const wmBulkLoadLoadFile = async (formData) => {
  return await axios.post(`${MODULE_BASE}/load`, formData)
}
