import * as types from '../mutations-types/index'

export default {
  [types.SET_ERROR] (state, error) {
    state.error = error
  },

  [types.SET_SUCCESS] (state, success) {
    state.success = success
  },
}
