import * as types from '../mutations-types'
import {
  createProviders,
  fetchProviders,
  updateProvider,
  activeProvider,
  deactiveProviders,
} from '../../api'

export default {
  async createProvider ({ commit, dispatch }, form) {
    commit(types.CREATE_PROVIDERS_REQUEST)
    const req = await createProviders(form)
    !req.error
      ? commit(types.CREATE_PROVIDERS_SUCCESS, req.data)
      : commit(types.CREATE_PROVIDERS_FAILURE, req)
    dispatch('fetchProviders')
  },

  async updateProvider ({ commit, dispatch }, form) {
    commit(types.UPDATE_PROVIDERS_REQUEST)
    const req = await updateProvider(form)
    req.ok
      ? commit(types.UPDATE_PROVIDERS_SUCCESS, req.data.user)
      : commit(types.UPDATE_PROVIDERS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchProviders')
  },

  async activeProvider ({ commit, dispatch }, form) {
    commit(types.UPDATE_PROVIDERS_REQUEST)
    const req = await activeProvider(form)
    req.ok
      ? commit(types.UPDATE_PROVIDERS_SUCCESS, req.data.user)
      : commit(types.UPDATE_PROVIDERS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchProviders')
  },

  async deactiveProviders ({ commit, dispatch }, form) {
    commit(types.UPDATE_PROVIDERS_REQUEST)
    const req = await deactiveProviders(form)
    req.ok
      ? commit(types.UPDATE_PROVIDERS_SUCCESS, req.data.user)
      : commit(types.UPDATE_PROVIDERS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('fetchProviders')
  },

  async fetchProviders ({ commit }, form) {
    commit(types.FETCH_PROVIDERS_REQUEST)
    const req = await fetchProviders(form)
    // // // // // // // // // // // // console.log(req)
    !req.error
      ? commit(types.FETCH_PROVIDERS_SUCCESS, req.data?.providers)
      : commit(types.FETCH_PROVIDERS_FAILURE, req)
  },
}
