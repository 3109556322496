import * as types from '../mutations-types'

export default {
  [types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_REQUEST] (state) {
    state.checkingTransactionsBulkRequestFile = true
  },
  [types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_SUCCESS] (state, req) {
    state.checkingTransactionsBulkRequestFile = false
    state.transactionsBulkRequestCheckingResult = req
  },
  [types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_FAILURE] (state, req) {
    state.checkingTransactionsBulkRequestFile = false
    state.transactionsBulkRequestCheckingResult = req
  },

  [types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_REQUEST] (state) {
    state.loadingTransactionsBulkRequestFile = true
  },
  [types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_SUCCESS] (state, req) {
    state.loadingTransactionsBulkRequestFile = false
    state.loadTransactionsBulkRequestResult = req
  },
  [types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_FAILURE] (state, req) {
    state.loadingTransactionsBulkRequestFile = false
    state.loadTransactionsBulkRequestResult = req
  },
}
