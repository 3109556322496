<template>
  <v-snackbar
    v-model="opened"
    color="success"
    top
    right
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'SuccessSnackbar',
    data () {
      return {
        opened: false,
        message: null,
      }
    },
    computed: {
      ...mapState([
        'success',
      ]),
    },
    watch: {
      success (success) {
        if (success !== null) {
          this.openSuccessSnackbar(success)
          setTimeout(this.closeSuccessSnackbar, 7000)
        }
      },
    },
    methods: {
      ...mapActions([
        'setSuccess',
      ]),
      openSuccessSnackbar (message) {
        this.opened = true
        this.message = message
      },
      closeSuccessSnackbar () {
        this.opened = false
        this.message = null
        this.setSuccess(null)
      },
    },
  }
</script>
