import * as types from '../mutations-types'
import {
  wmBulkLoadLoadFile,
  wmBulkLoadCheckFile,
} from '../../api'

export default {
  async wmBulkLoadCheckFile ({ commit }, formData) {
    commit(types.CHECK_WM_BULK_LOAD_FILE_REQUEST)
    const req = await wmBulkLoadCheckFile(formData)
    req.ok
      ? commit(types.CHECK_WM_BULK_LOAD_FILE_SUCCESS, req.data)
      : commit(types.CHECK_WM_BULK_LOAD_FILE_FAILURE, req.response.data)
  },
  async wmBulkLoadLoadFile ({ commit }, formData) {
    commit(types.LOAD_WM_BULK_LOAD_FILE_REQUEST)
    const req = await wmBulkLoadLoadFile(formData)
    req.ok
      ? commit(types.LOAD_WM_BULK_LOAD_FILE_SUCCESS, req.data)
      : commit(types.LOAD_WM_BULK_LOAD_FILE_FAILURE, req.response.data)
  },
}
