import axios from 'axios'

export const fetchWarehouseInventory = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getWarehouseInventaryByWarehouseId/' + id)
}

export const fetchStoreInventory = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getStoreInventaryByWarehouseId/' + id)
}
