import * as types from '../mutations-types'

export default {
  [types.PRODUCTS_BULK_LOAD_REQUEST] (state) {
    state.productsBulkLoading = true
  },
  [types.PRODUCTS_BULK_LOAD_SUCCESS] (state) {
    state.productsBulkLoading = false
    state.success = 'Maked the products bulk-load successfully'
  },
  [types.PRODUCTS_BULK_LOAD_FAILURE] (state, error) {
    state.productsBulkLoading = false
    state.error = error.response?.data?.message || 'Ha ocurrido un error inesperado'
  },
}
