import * as types from '../mutations-types'

export default {
  [types.FETCH_WAREHOUSE_MOVEMENTS_REQUEST] (state) {
    state.fetchingWarehouseMovements = true
  },

  [types.FETCH_WAREHOUSE_MOVEMENTS_SUCCESS] (state, warhouseMovements) {
    state.warhouseMovements = warhouseMovements
    state.fetchingWarehouseMovements = false
  },

  [types.FETCH_WAREHOUSE_MOVEMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseMovements = false
  },

  [types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_REQUEST] (state) {
    state.fetchingWarehouseMovementsDetail = true
  },

  [types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_SUCCESS] (state, warhouseMovementsDetail) {
    state.warhouseMovementsDetail = warhouseMovementsDetail
    state.fetchingWarehouseMovementsDetail = false
  },

  [types.FETCH_WAREHOUSE_MOVEMENTS_DETAIL_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseMovementsDetail = false
  },

  [types.CREATE_WAREHOUSE_MOVEMENTS_REQUEST] (state) {
    state.fetchingWarehouseMovements = true
  },

  [types.CREATE_WAREHOUSE_MOVEMENTS_SUCCESS] (state) {
    state.success = 'Se ha realizado el movimiento con exito'
    state.fetchingWarehouseMovements = false
  },

  [types.CREATE_WAREHOUSE_MOVEMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseMovements = false
  },

  [types.DELETE_WAREHOUSE_MOVEMENTS_REQUEST] (state) {
    state.fetchingWarehouseMovements = true
  },

  [types.DELETE_WAREHOUSE_MOVEMENTS_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el producto'
    state.fetchingWarehouseMovements = false
  },

  [types.DELETE_WAREHOUSE_MOVEMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseMovements = false
  },

  [types.ACTIVE_WAREHOUSE_MOVEMENTS_REQUEST] (state) {
    state.fetchingWarehouseMovements = true
  },

  [types.ACTIVE_WAREHOUSE_MOVEMENTS_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el producto'
    state.fetchingWarehouseMovements = false
  },

  [types.ACTIVE_WAREHOUSE_MOVEMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseMovements = false
  },

  [types.UPDATE_WAREHOUSE_MOVEMENTS_REQUEST] (state) {
    state.fetchingWarehouseMovements = true
  },

  [types.UPDATE_WAREHOUSE_MOVEMENTS_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingWarehouseMovements = false
  },

  [types.UPDATE_WAREHOUSE_MOVEMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseMovements = false
  },
}
