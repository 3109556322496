import * as types from '../mutations-types'
import {
  fetchProductType,
  createProductType,
  deleteProductType,
  activeProductType,
  updateProductType,
} from '@/api'

export default {
  async fetchProductType ({ commit }, payload) {
    commit(types.FETCH_PRODUCT_TYPE_REQUEST)
    const req = await fetchProductType(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_PRODUCT_TYPE_SUCCESS, req.data.productTypes)
      : commit(types.FETCH_PRODUCT_TYPE_FAILURE, req)
  },

  async createProductType ({ commit }, payload) {
    commit(types.CREATE_PRODUCT_TYPE_REQUEST)
    const req = await createProductType(payload)
    req.ok
      ? commit(types.CREATE_PRODUCT_TYPE_SUCCESS)
      : commit(types.CREATE_PRODUCT_TYPE_FAILURE, req)
  },

  async deleteProductType ({ commit }, { id }) {
    commit(types.DELETE_PRODUCT_TYPE_REQUEST)
    const req = await deleteProductType(id)
    req.ok
      ? commit(types.DELETE_PRODUCT_TYPE_SUCCESS)
      : commit(types.DELETE_PRODUCT_TYPE_FAILURE, req)
  },

  async activeProductType ({ commit }, { id }) {
    commit(types.ACTIVE_PRODUCT_TYPE_REQUEST)
    const req = await activeProductType(id)
    req.ok
      ? commit(types.ACTIVE_PRODUCT_TYPE_SUCCESS)
      : commit(types.ACTIVE_PRODUCT_TYPE_FAILURE, req)
  },

  async updateProductType ({ commit }, payload) {
    commit(types.UPDATE_PRODUCT_TYPE_REQUEST)
    const req = await updateProductType(payload)
    req.ok
      ? commit(types.UPDATE_PRODUCT_TYPE_SUCCESS)
      : commit(types.UPDATE_PRODUCT_TYPE_FAILURE, req)
  },
}
