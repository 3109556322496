export const FETCH_WAREHOUSE_MOVEMENTS_REQUEST = 'FETCH_WAREHOUSE_MOVEMENTS_REQUEST'
export const FETCH_WAREHOUSE_MOVEMENTS_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENTS_SUCCESS'
export const FETCH_WAREHOUSE_MOVEMENTS_FAILURE = 'FETCH_WAREHOUSE_MOVEMENTS_FAILURE'

export const FETCH_WAREHOUSE_MOVEMENTS_DETAIL_REQUEST = 'FETCH_WAREHOUSE_MOVEMENTS_DETAIL_REQUEST'
export const FETCH_WAREHOUSE_MOVEMENTS_DETAIL_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENTS_DETAIL_SUCCESS'
export const FETCH_WAREHOUSE_MOVEMENTS_DETAIL_FAILURE = 'FETCH_WAREHOUSE_MOVEMENTS_DETAIL_FAILURE'

export const CREATE_WAREHOUSE_MOVEMENTS_REQUEST = 'CREATE_WAREHOUSE_MOVEMENTS_REQUEST'
export const CREATE_WAREHOUSE_MOVEMENTS_SUCCESS = 'CREATE_WAREHOUSE_MOVEMENTS_SUCCESS'
export const CREATE_WAREHOUSE_MOVEMENTS_FAILURE = 'CREATE_WAREHOUSE_MOVEMENTS_FAILURE'

export const DELETE_WAREHOUSE_MOVEMENTS_REQUEST = 'DELETE_WAREHOUSE_MOVEMENTS_REQUEST'
export const DELETE_WAREHOUSE_MOVEMENTS_SUCCESS = 'DELETE_WAREHOUSE_MOVEMENTS_SUCCESS'
export const DELETE_WAREHOUSE_MOVEMENTS_FAILURE = 'DELETE_WAREHOUSE_MOVEMENTS_FAILURE'

export const ACTIVE_WAREHOUSE_MOVEMENTS_REQUEST = 'ACTIVE_WAREHOUSE_MOVEMENTS_REQUEST'
export const ACTIVE_WAREHOUSE_MOVEMENTS_SUCCESS = 'ACTIVE_WAREHOUSE_MOVEMENTS_SUCCESS'
export const ACTIVE_WAREHOUSE_MOVEMENTS_FAILURE = 'ACTIVE_WAREHOUSE_MOVEMENTS_FAILURE'

export const UPDATE_WAREHOUSE_MOVEMENTS_REQUEST = 'UPDATE_WAREHOUSE_MOVEMENTS_REQUEST'
export const UPDATE_WAREHOUSE_MOVEMENTS_SUCCESS = 'UPDATE_WAREHOUSE_MOVEMENTS_SUCCESS'
export const UPDATE_WAREHOUSE_MOVEMENTS_FAILURE = 'UPDATE_WAREHOUSE_MOVEMENTS_FAILURE'
