import * as types from '../mutations-types/index'

export default {
  [types.BULK_LOAD_REQUEST] (state) {
    state.bulkLoading = true
  },
  [types.BULK_LOAD_SUCCESS] (state, data) {
    state.bulkLoadData = data
    state.bulkLoading = false
  },
  [types.BULK_LOAD_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.bulkLoading = false
  },
}
