import * as types from '../mutations-types'

export default {
  [types.FETCH_PRODUCT_CLASSIFICATION_REQUEST] (state) {
    state.fetchingProductClassification = true
  },

  [types.FETCH_PRODUCT_CLASSIFICATION_SUCCESS] (state, productClassification) {
    state.productClassification = productClassification
    state.fetchingProductClassification = false
  },

  [types.FETCH_PRODUCT_CLASSIFICATION_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingProductClassification = false
  },

  [types.CREATE_PRODUCT_CLASSIFICATION_REQUEST] (state) {
    state.fetchingProductClassification = true
  },

  [types.CREATE_PRODUCT_CLASSIFICATION_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito la clasificacion de productos'
    state.fetchingProductClassification = false
  },

  [types.CREATE_PRODUCT_CLASSIFICATION_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductClassification = false
  },

  [types.DELETE_PRODUCT_CLASSIFICATION_REQUEST] (state) {
    state.fetchingProductClassification = true
  },

  [types.DELETE_PRODUCT_CLASSIFICATION_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente la clasificacion de producto'
    state.fetchingProductClassification = false
  },

  [types.DELETE_PRODUCT_CLASSIFICATION_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductClassification = false
  },

  [types.ACTIVE_PRODUCT_CLASSIFICATION_REQUEST] (state) {
    state.fetchingProductClassification = true
  },

  [types.ACTIVE_PRODUCT_CLASSIFICATION_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente la clasificacion de producto'
    state.fetchingProductClassification = false
  },

  [types.ACTIVE_PRODUCT_CLASSIFICATION_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductClassification = false
  },

  [types.UPDATE_PRODUCT_CLASSIFICATION_REQUEST] (state) {
    state.fetchingProductClassification = true
  },

  [types.UPDATE_PRODUCT_CLASSIFICATION_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito la clasificacion de producto'
    state.fetchingProductClassification = false
  },

  [types.UPDATE_PRODUCT_CLASSIFICATION_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductClassification = false
  },
}
