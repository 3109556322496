import * as types from '../mutations-types/index'
import { productsBulkLoad } from '../../api'

export default {
  async productsBulkLoad ({ commit }, formData) {
    commit(types.PRODUCTS_BULK_LOAD_REQUEST)
    const req = await productsBulkLoad(formData)
    req.ok
      ? commit(types.PRODUCTS_BULK_LOAD_SUCCESS)
      : commit(types.PRODUCTS_BULK_LOAD_FAILURE, req)
  },
}
