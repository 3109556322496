import * as types from '../mutations-types/index.js'

export default {
  [types.SET_BAR_IMAGE] (state, payload) {
    state.barImage = payload
  },

  [types.SET_DRAWER] (state, payload) {
    state.drawer = payload
  },

  [types.SET_ACTIONS_BUSINESS] (state, payload) {
    state.actionsBusiness = payload
  },

  [types.SET_ACTIONS_PARENT_BUSINESS] (state, payload) {
    state.actionsParentBusiness = payload
  },

  [types.SET_ACTIONS_ENVIRONMENT] (state, payload) {
    state.actionEnvironment = payload
  },

  [types.SET_ACTIONS_ENVIRONMENT_DATA] (state, payload) {
    state.actionEnvironmentData = payload
  },

  [types.SET_ACTIONS_WAREHOUSE] (state, payload) {
    state.actionWarehouse = payload
  },
}
