export const CREATE_CLIENTS_REQUEST = 'CREATE_CLIENTS_REQUEST'
export const CREATE_CLIENTS_SUCCESS = 'CREATE_CLIENTS_SUCCESS'
export const CREATE_CLIENTS_FAILURE = 'CREATE_CLIENTS_FAILURE'

export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const FETCH_CLIENTS_ALL_REQUEST = 'FETCH_CLIENTS_ALL_REQUEST'
export const FETCH_CLIENTS_ALL_SUCCESS = 'FETCH_CLIENTS_ALL_SUCCESS'
export const FETCH_CLIENTS_ALL_FAILURE = 'FETCH_CLIENTS_ALL_FAILURE'

export const UPDATE_CLIENTS_REQUEST = 'UPDATE_CLIENTS_REQUEST'
export const UPDATE_CLIENTS_SUCCESS = 'UPDATE_CLIENTS_SUCCESS'
export const UPDATE_CLIENTS_FAILURE = 'UPDATE_CLIENTS_FAILURE'

export const DELETE_CLIENTS_REQUEST = 'DELETE_CLIENTS_REQUEST'
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS'
export const DELETE_CLIENTS_FAILURE = 'DELETE_CLIENTS_FAILURE'
