import * as types from '../mutations-types'

export default {
  [types.FETCH_WAREHOUSE_INPUTS_REQUEST] (state) {
    state.fetchingWarehouseInputs = true
  },

  [types.FETCH_WAREHOUSE_INPUTS_SUCCESS] (state, warhouseInputs) {
    state.warhouseInputs = warhouseInputs
    state.fetchingWarehouseInputs = false
  },

  [types.FETCH_WAREHOUSE_INPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseInputs = false
  },

  [types.FETCH_WAREHOUSE_INPUTS_DETAIL_REQUEST] (state) {
    state.fetchingWarehouseInputsDetail = true
  },

  [types.FETCH_WAREHOUSE_INPUTS_DETAIL_SUCCESS] (state, warhouseInputsDetail) {
    state.warhouseInputsDetail = warhouseInputsDetail
    state.fetchingWarehouseInputsDetail = false
  },

  [types.FETCH_WAREHOUSE_INPUTS_DETAIL_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseInputsDetail = false
  },

  [types.CREATE_WAREHOUSE_INPUTS_REQUEST] (state) {
    state.fetchingWarehouseInputs = true
  },

  [types.CREATE_WAREHOUSE_INPUTS_SUCCESS] (state) {
    state.success = 'Se ha generado la entrada con exito'
    state.fetchingWarehouseInputs = false
  },

  [types.CREATE_WAREHOUSE_INPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseInputs = false
  },

  [types.DELETE_WAREHOUSE_INPUTS_REQUEST] (state) {
    state.fetchingWarehouseInputs = true
  },

  [types.DELETE_WAREHOUSE_INPUTS_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el producto'
    state.fetchingWarehouseInputs = false
  },

  [types.DELETE_WAREHOUSE_INPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseInputs = false
  },

  [types.ACTIVE_WAREHOUSE_INPUTS_REQUEST] (state) {
    state.fetchingWarehouseInputs = true
  },

  [types.ACTIVE_WAREHOUSE_INPUTS_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el producto'
    state.fetchingWarehouseInputs = false
  },

  [types.ACTIVE_WAREHOUSE_INPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseInputs = false
  },

  [types.UPDATE_WAREHOUSE_INPUTS_REQUEST] (state) {
    state.fetchingWarehouseInputs = true
  },

  [types.UPDATE_WAREHOUSE_INPUTS_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingWarehouseInputs = false
  },

  [types.UPDATE_WAREHOUSE_INPUTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseInputs = false
  },
}
