import generalMutations from './mutations/general'
import welcomeMutations from './mutations/welcome'
import loginMutations from './mutations/login'
import usersMutations from './mutations/users'
import miscsMutations from './mutations/misc'
import signupMutations from './mutations/signup'
import recoverPasswordMutations from './mutations/recover-password'
import setPassword from './mutations/set-password'
import business from './mutations/business'
import currencies from './mutations/currencies'
import adminStoreMutations from './mutations/admin-store'
import warehouses from './mutations/warehouses'
import adminWarehousesTypes from './mutations/admin-warehouses-types'
import productLine from './mutations/product-line'
import productClassification from './mutations/product-classification'
import productCategory from './mutations/product-category'
import productType from './mutations/product-type'
import products from './mutations/products'
import warehouseInputs from './mutations/warehouse-inputs'
import warehouseOutputs from './mutations/warehouse-outputs'
import warehouseMovements from './mutations/warehouse-movements'
import warehouseInventory from './mutations/warehouse-inventory'
import bulkLoad from './mutations/bulk-load'
import productsBulkLoad from './mutations/products-bulk-load'
import clients from './mutations/clients'
import warehouseConcessions from './mutations/warehouse-concessions'
import paymentsMethods from './mutations/payments-methods'
import warehousesMovementsBulkLoad from './mutations/warehouses-movements-bulk-load'
import transactionsBulkLoad from './mutations/transactions-bulk-load'
import businessSales from './mutations/business-sales'
import reports from './mutations/reports'
import providers from './mutations/providers'

export default {
  ...generalMutations,
  ...welcomeMutations,
  ...loginMutations,
  ...usersMutations,
  ...miscsMutations,
  ...signupMutations,
  ...recoverPasswordMutations,
  ...setPassword,
  ...business,
  ...currencies,
  ...adminStoreMutations,
  ...warehouses,
  ...adminWarehousesTypes,
  ...productLine,
  ...productClassification,
  ...productCategory,
  ...productType,
  ...products,
  ...warehouseInputs,
  ...warehouseOutputs,
  ...warehouseMovements,
  ...warehouseConcessions,
  ...warehouseInventory,
  ...bulkLoad,
  ...productsBulkLoad,
  ...clients,
  ...paymentsMethods,
  ...warehousesMovementsBulkLoad,
  ...transactionsBulkLoad,
  ...businessSales,
  ...reports,
  ...providers,
}
