export const FETCH_PARENTS_BUSINESS_REQUEST = 'FETCH_PARENTS_BUSINESS_REQUEST'
export const FETCH_PARENTS_BUSINESS_SUCCESS = 'FETCH_PARENTS_BUSINESS_SUCCESS'
export const FETCH_PARENTS_BUSINESS_FAILURE = 'FETCH_PARENTS_BUSINESS_FAILURE'

export const FETCH_PARENTS_BUSINESS_BY_ID_SUCCESS = 'FETCH_PARENTS_BUSINESS_BY_ID_SUCCESS'
export const FETCH_PARENTS_BUSINESS_BY_ID_REQUEST = 'FETCH_PARENTS_BUSINESS_BY_ID_REQUEST'
export const FETCH_PARENTS_BUSINESS_BY_ID_FAILURE = 'FETCH_PARENTS_BUSINESS_BY_ID_FAILURE'

export const FETCH_CHILDS_BUSINESS_REQUEST = 'FETCH_CHILDS_BUSINESS_REQUEST'
export const FETCH_CHILDS_BUSINESS_SUCCESS = 'FETCH_CHILDS_BUSINESS_SUCCESS'
export const FETCH_CHILDS_BUSINESS_FAILURE = 'FETCH_CHILDS_BUSINESS_FAILURE'

export const GET_BUSINESS_CHART_DATA_REQUEST = 'GET_BUSINESS_CHART_DATA_REQUEST'
export const GET_BUSINESS_CHART_DATA_SUCCESS = 'GET_BUSINESS_CHART_DATA_SUCCESS'
export const GET_BUSINESS_CHART_DATA_FAILURE = 'GET_BUSINESS_CHART_DATA_FAILURE'
