import * as types from '../mutations-types'
import { login } from '../../api'

export default {
  async login ({ commit }, { email, password }) {
    commit(types.LOGIN_REQUEST)
    const req = await login({ email, password })
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('login', req)
    req.ok
      ? commit(types.LOGIN_SUCCESS, req.data)
      : commit(types.LOGIN_FAILURE, req)
  },
}
