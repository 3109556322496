import axios from 'axios'

export const createProviders = async (form) => {
  return await axios.post('/api/v1/providers/', form)
}

export const updateProvider = async form => {
  return await axios.patch(`/api/v1/providers/${form.id}`, form)
}
export const activeProvider = async form => {
  return await axios.patch(`/api/v1/providers/active/${form.id}`, form)
}
export const deactiveProviders = async form => {
  return await axios.patch(`/api/v1/providers/deactive/${form.id}`, form)
}

export const fetchProviders = async () => {
  return await axios.get('/api/v1/providers/')
}
