import * as types from '../mutations-types/index'
import { signup } from '../../api'

export default {
  async makeSignup ({ commit }, { name, password, email, role }) {
    commit(types.SIGNUP_REQUEST)
    const req = await signup({ name, password, email, role })
    req.ok
      ? commit(types.SIGNUP_SUCCESS, req.data)
      : commit(types.SIGNUP_FAILURE, req)
  },
}
