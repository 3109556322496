import * as types from '../mutations-types'

export default {
  [types.FETCH_PRODUCT_REQUEST] (state) {
    state.fetchingProducts = true
  },

  [types.FETCH_PRODUCT_SUCCESS] (state, products) {
    state.products = products
    state.fetchingProducts = false
  },

  [types.FETCH_PRODUCT_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingProducts = false
  },

  [types.CREATE_PRODUCT_REQUEST] (state) {
    state.fetchingProducts = true
  },

  [types.CREATE_PRODUCT_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito el productos'
    state.fetchingProducts = false
  },

  [types.CREATE_PRODUCT_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProducts = false
  },

  [types.DELETE_PRODUCT_REQUEST] (state) {
    state.fetchingProducts = true
  },

  [types.DELETE_PRODUCT_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el producto'
    state.fetchingProducts = false
  },

  [types.DELETE_PRODUCT_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProducts = false
  },

  [types.ACTIVE_PRODUCT_REQUEST] (state) {
    state.fetchingProducts = true
  },

  [types.ACTIVE_PRODUCT_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el producto'
    state.fetchingProducts = false
  },

  [types.ACTIVE_PRODUCT_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProducts = false
  },

  [types.UPDATE_PRODUCT_REQUEST] (state) {
    state.fetchingProducts = true
  },

  [types.UPDATE_PRODUCT_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingProducts = false
  },

  [types.UPDATE_PRODUCT_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProducts = false
  },
}
