import * as types from '../mutations-types/index'

export default {
  [types.SIGNUP_REQUEST] (state) {
    state.makingSignup = true
  },

  [types.SIGNUP_SUCCESS] (state, signupData) {
    state.signupData = signupData
    state.success = 'Su registro a sido exitoso, se a enviado una clave temporal a su correo'
    state.makingSignup = false
  },

  [types.SIGNUP_FAILURE] (state, req) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req)
    state.makingSignup = false
    state.error = `Error al intentar registrarse, código de error: ${req.response.data.message}`
  },
}
