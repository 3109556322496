export const CREATE_PROVIDERS_REQUEST = 'CREATE_PROVIDERS_REQUEST'
export const CREATE_PROVIDERS_SUCCESS = 'CREATE_PROVIDERS_SUCCESS'
export const CREATE_PROVIDERS_FAILURE = 'CREATE_PROVIDERS_FAILURE'

export const UPDATE_PROVIDERS_REQUEST = 'UPDATE_PROVIDERS_REQUEST'
export const UPDATE_PROVIDERS_SUCCESS = 'UPDATE_PROVIDERS_SUCCESS'
export const UPDATE_PROVIDERS_FAILURE = 'UPDATE_PROVIDERS_FAILURE'

export const FETCH_PROVIDERS_REQUEST = 'FETCH_PROVIDERS_REQUEST'
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS'
export const FETCH_PROVIDERS_FAILURE = 'FETCH_PROVIDERS_FAILURE'
