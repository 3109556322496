import axios from 'axios'
import { TYPE_TRANSACTION } from '../../const'

export const fetchWarhouseInputs = async (v) => {
  // // // // console.log('id', id)
  // // // // console.log('entrada link', '/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.entrada)
  // return await axios.get('/api/v1/transactions/getWarehousesId/' + v.id + '?typeTransactionId=' + TYPE_TRANSACTION.entrada)
  // // console.log('vv', v)
  return await axios.get('/api/v1/transactions/getWarehousesId/' + v.id, {
    params: {
      typeTransactionId: TYPE_TRANSACTION.entrada,
      ...v,
    },
  })
}

export const fetchWarehouseSalesReturns = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  // // // // // // // // // // // // // // // // // // // // // // // console.log('entrada link', '/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.salida)

  /*
    TYPE_TRANSACTION.devolucion_por_venta
  */
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.devolucion_por_concesion)
}

export const fetchWarhouseInputsDetail = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const createWarhouseInputs = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/transactions/input', payload)
}

export const deleteWarhouseInputs = async id => {
  return await axios.post(`/api/v1/products/deactive/${id}`)
}

export const activeWarhouseInputs = async id => {
  return await axios.post(`/api/v1/products/active/${id}`)
}

export const updateWarhouseInputs = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
