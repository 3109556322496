import * as types from '../mutations-types'
import {
  fetchReports,
  fetchReportsDetail,
  fetchReportsCurrency,
  fetchReportsMaterial,
  fetchReportsMaterialType,
} from '@/api'

export default {
  async fetchReports ({ commit }, payload) {
    commit(types.FETCH_REPORTS_REQUEST)
    const req = await fetchReports(payload)
    req.ok
      ? commit(types.FETCH_REPORTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_REPORTS_FAILURE, req)
  },

  async fetchReportsMaterial ({ commit }, payload) {
    commit(types.FETCH_REPORTS_REQUEST)
    // // // console.log('Reports', payload)
    const req = await fetchReportsMaterial(payload)
    // // // console.log('REQ', req)
    req.ok
      ? commit(types.FETCH_REPORTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_REPORTS_FAILURE, req)
  },

  async fetchReportsMaterialType ({ commit }, payload) {
    commit(types.FETCH_REPORTS_DETAIL_REQUEST)
    const req = await fetchReportsMaterialType(payload)
    // // // console.log('req', req)
    req.ok
      ? commit(types.FETCH_REPORTS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_REPORTS_DETAIL_FAILURE, req)
  },

  async fetchReportsDetail ({ commit }, payload) {
    commit(types.FETCH_REPORTS_DETAIL_REQUEST)
    const req = await fetchReportsDetail(payload)
    req.ok
      ? commit(types.FETCH_REPORTS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_REPORTS_DETAIL_FAILURE, req)
  },

  async fetchReportsCurrency ({ commit }, payload) {
    commit(types.FETCH_REPORTS_CURRENCY_REQUEST)
    const req = await fetchReportsCurrency(payload)
    req.ok
      ? commit(types.FETCH_REPORTS_CURRENCY_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_REPORTS_CURRENCY_FAILURE, req)
  },
}
