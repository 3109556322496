import axios from 'axios'

const MODULE_BASE = '/api/v1/transactions-bulk-load'

export const transactionsBulkLoadCheckFile = async (formData) => {
  return await axios.post(`${MODULE_BASE}/check`, formData)
}

export const transactionsBulkLoadLoadFile = async (formData) => {
  return await axios.post(`${MODULE_BASE}/load`, formData)
}
