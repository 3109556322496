import * as types from '../mutations-types/index.js'

export default {
  [types.FETCH_WAREHOUSES_REQUEST] (state) {
    state.fetchingWarehouses = true
  },

  [types.FETCH_WAREHOUSES_SUCCESS] (state, warehouses) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(warehouses)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(state.warehouses)
    state.warehouses = warehouses
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('despues', state.warehouses)
    state.fetchingWarehouses = false
  },

  [types.FETCH_WAREHOUSES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingWarehouses = false
  },

  [types.FETCH_WAREHOUSES_BY_ID_REQUEST] (state) {
    state.fetchingWarehouses = true
  },

  [types.FETCH_WAREHOUSES_BY_ID_SUCCESS] (state, warehouses) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(warehouses)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(state.warehouses)
    state.warehouseById = warehouses
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('despues', state.warehouses)
    state.fetchingWarehouses = false
  },

  [types.FETCH_WAREHOUSES_BY_ID_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingWarehouses = false
  },

  [types.FETCH_STORE_WAREHOUSES_REQUEST] (state) {
    state.fetchingStoreWarehouses = true
  },

  [types.FETCH_STORE_WAREHOUSES_SUCCESS] (state, warehouses) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(warehouses)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(state.warehouses)
    state.storeWarehouses = warehouses
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('despues', state.warehouses)
    state.fetchingStoreWarehouses = false
  },

  [types.FETCH_STORE_WAREHOUSES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingStoreWarehouses = false
  },

  [types.SET_BUSINESS_BY_WAREHOUSES_REQUEST] (state) {
    state.fetchingStoreWarehouses = true
  },

  [types.SET_BUSINESS_BY_WAREHOUSES_SUCCESS] (state, business) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(warehouses)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(state.warehouses)
    state.businessWarehouses = business
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('despues', state.warehouses)
    state.fetchingStoreWarehouses = false
  },

  [types.SET_BUSINESS_BY_WAREHOUSES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingStoreWarehouses = false
  },

  [types.SET_WAREHOUSES_REQUEST] (state) {
    state.fetchingWarehouses = true
  },

  [types.SET_WAREHOUSES_SUCCESS] (state, warehouses) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('despues', state.warehouses)
    state.success = 'Se han agregado los roles satisfactoriamente'
    state.fetchingWarehouses = false
  },

  [types.SET_WAREHOUSES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingWarehouses = false
  },

  [types.CREATE_WAREHOUSES_REQUEST] (state) {
    state.fetchingWarehouses = true
  },

  [types.CREATE_WAREHOUSES_SUCCESS] (state, user) {
    // state.users = [...state.users, user]
    state.success = 'Se ha registrado el almacen satisfactoriamente'
    state.fetchingWarehouses = false
  },

  [types.CREATE_WAREHOUSES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingWarehouses = false
  },

  [types.UPDATE_WAREHOUSES_REQUEST] (state) {
    state.fetchingWarehouses = true
  },

  [types.UPDATE_WAREHOUSES_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el tipo de almacen'
    state.fetchingWarehouses = false
  },

  [types.UPDATE_WAREHOUSES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouses = false
  },

  [types.DELETE_WAREHOUSES_REQUEST] (state) {
    state.success = 'Se ha desactivado correctamente el almacen'
    state.fetchingWarehouses = true
  },

  [types.DELETE_WAREHOUSES_SUCCESS] (state) {
    state.fetchingWarehouses = false
  },

  [types.DELETE_WAREHOUSES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouses = false
  },

  [types.ACTIVATE_WAREHOUSES_REQUEST] (state) {
    state.success = 'Se ha activado correctamente el almacen'
    state.fetchingWarehouses = true
  },

  [types.ACTIVATE_WAREHOUSES_SUCCESS] (state) {
    state.fetchingWarehouses = false
  },

  [types.ACTIVATE_WAREHOUSES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouses = false
  },

  [types.DEACTIVATE_WAREHOUSES_REQUEST] (state) {
    state.success = 'Se ha revocado el permiso del almacen correctamente el almacen'
    state.fetchingWarehouses = true
  },

  [types.DEACTIVATE_WAREHOUSES_SUCCESS] (state) {
    state.fetchingWarehouses = false
  },

  [types.DEACTIVATE_WAREHOUSES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouses = false
  },

  [types.GET_WAREHOUSES_INPUT_CHART_DATA_REQUEST] (state) {
    state.gettingWarehousesInputChartData = true
  },

  [types.GET_WAREHOUSES_INPUT_CHART_DATA_SUCCESS] (state, data) {
    state.warehousesInputChartData = data
    state.gettingWarehousesInputChartData = false
  },

  [types.GET_WAREHOUSES_INPUT_CHART_DATA_FAILURE] (state, req) {
    const message = req.response?.data?.message || 'Upps, ha ocurrido un error inesperado'
    state.error = message
    state.gettingWarehousesInputChartData = false
  },

  [types.GET_WAREHOUSES_OUTPUT_CHART_DATA_REQUEST] (state) {
    state.gettingWareousesOutputChartData = true
  },

  [types.GET_WAREHOUSES_OUTPUT_CHART_DATA_SUCCESS] (state, data) {
    state.warehousesOutputChartData = data
    state.gettingWarehousesOutputChartData = false
  },

  [types.GET_WAREHOUSES_OUTPUT_CHART_DATA_FAILURE] (state, req) {
    const message = req.response?.data?.message || 'Upps, ha ocurrido un error inesperado'
    state.error = message
    state.gettingWareousesOutputChartData = false
  },

  [types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_REQUEST] (state) {
    state.gettingWarehousesConcessionedChartData = true
  },

  [types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_SUCCESS] (state, data) {
    state.warehousesConcessionedChartData = data
    state.gettingWarehousesConcessionedChartData = false
  },

  [types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_FAILURE] (state, req) {
    const message = req.response?.data?.message || 'Upps, ha ocurrido un error inesperado'
    state.error = message
    state.gettingWarehousesConcessionedChartData = false
  },
}
