import * as types from '../mutations-types'

export default {
  [types.FETCH_PARENT_CHILDS_REQUEST] (state) {
    state.fetchingParentChilds = true
  },

  [types.FETCH_PARENT_CHILDS_SUCCESS] (state, business) {
    state.businessParentsChilds = business
    state.fetchingParentChilds = false
  },

  [types.FETCH_PARENT_CHILDS_FAILURE] (state, error) {
    // // // // // // // // // // console.error('error', error)
    state.error = error.response.data.message
    state.fetchingParentChilds = false
  },

  [types.CREATE_BUSINESS_REQUEST] (state) {
    state.creatingBusiness = true
  },

  [types.CREATE_BUSINESS_SUCCESS] (state) {
    state.success = 'La empresa se ha creado satisfactoriamente'
    state.creatingBusiness = false
  },

  [types.CREATE_BUSINESS_FAILURE] (state, error) {
    // // // // // // // // // // console.error('error', error)
    state.error = error.response.data.message
    state.creatingBusiness = false
  },

  [types.DEACTIVE_BUSINESS_REQUEST] (state) {
    state.deactivingBusiness = true
  },

  [types.DEACTIVE_BUSINESS_SUCCESS] (state) {
    state.success = 'La empresa se ha desactivado satisfactoriamente'
    state.deactivingBusiness = false
  },

  [types.DEACTIVE_BUSINESS_FAILURE] (state, error) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('error', error)
    state.error = error.response.data.message
    state.deactivingBusiness = false
  },

  [types.UPDATE_BUSINESS_REQUEST] (state) {
    state.updatingBusiness = true
  },

  [types.UPDATE_BUSINESS_SUCCESS] (state) {
    state.success = 'Se ha actualizado satisfactoriamente la tienda'
    state.updatingBusiness = false
  },

  [types.UPDATE_BUSINESS_FAILURE] (state, error) {
    // // // // // // // // // // console.error('error', error)
    state.error = error.response.data.message
    state.updatingBusiness = false
  },

  [types.UPDATE_ACCESS] (state, access) {
    state.access = access
  },
}
