export default {
  gettingWarehousesInputChartData: false,
  warehousesInputChartData: undefined,
  gettingWarehousesOutputChartData: false,
  warehousesOutputChartData: undefined,
  gettingWarehousesConcessionedChartData: false,
  warehousesConcessionedChartData: undefined,
  gettingBusinessChartData: false,
  businessChartData: undefined,
  checkingWmBulkRequestFile: false,
  loadingWmBulkRequestFile: false,
  loadingTransactionsBulkRequestFile: false,
  loadWmBulkRequestResult: undefined,
  loadTransactionsBulkRequestResult: undefined,
  wmBulkRequestCheckingResult: undefined,
  transactionsBulkRequestCheckingResult: undefined,
  productsBulkLoading: false,
  bulkLoadData: {},
  bulkLoading: false,
  fetchingData: false,
  fetchingParentChilds: false,
  fetchingWarehousesTypes: false,
  creatingWarehousesTypes: false,
  creatingBusiness: false,
  deactivingWarehouseType: false,
  activingWarehouseType: false,
  updatingWarehouseType: false,
  deactivingBusiness: false,
  updatingBusiness: false,
  businessParentsChilds: [],
  warehousesTypes: [],
  barColor: 'rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)',
  barImage:
    'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
  drawer: null,
  error: null,
  success: null,
  fetchingWelcome: false,
  welcome: null,
  fetchingLogin: false,
  loginData: null,
  fetchingUser: false,
  user: null,
  users: [],
  userBySucursalId: [],
  signupData: null,
  makingSignup: false,
  fetchingResetPassword: false,
  fetchingRoles: false,
  roles: [],
  actionsBusiness: false,
  actionsParentBusiness: false,
  actionEnvironment: false,
  actionEnvironmentData: false,
  actionWarehouse: false,
  fetchingBusiness: false,
  parentBusiness: [],
  childsBusiness: [],
  warehouses: [],
  warehouseById: {},
  fetchingWarehouses: false,
  fetchingCurrencies: [],
  currencies: [],
  createUserResponse: {},
  fetchingProductLine: false,
  productLine: [],
  fetchingProductClassification: false,
  productClassification: [],
  fetchingProductCategory: false,
  productCategory: [],
  fetchingProductType: false,
  productType: [],
  fetchingProducts: false,
  products: [],
  fetchingWarehouseInputs: false,
  warhouseInputs: [],
  fetchingWarehouseInputsDetail: false,
  warhouseInputsDetail: [],
  fetchingWarehouseOutputs: false,
  warhouseOutputs: [],
  fetchingWarehouseOutputsDetail: false,
  warhouseOutputsDetail: [],
  fetchingWarehouseMovements: false,
  warhouseMovements: [],
  fetchingWarehouseMovementsDetail: false,
  warhouseMovementsDetail: [],
  fechingWarehouseInventory: false,
  warehousesInventory: [],
  fechingStoreInventory: false,
  storeInventory: [],
  fecthingClients: false,
  createClientResponse: [],
  clientsSearch: {},
  clients: [],
  fetchingProviders: false,
  providers: [],
  fetchingStoreWarehouses: false,
  storeWarehouses: [],
  businessWarehouses: [],
  fetchingWarehouseConcessions: false,
  warehousesConcessions: [],
  searchParamsConcessions: {},
  fetchingWarehouseConcessionsDetail: false,
  warhouseConcessionsDetail: [],
  fetchingPaymentsMethods: false,
  paymentMethods: [],
  fetchingBusinessSales: false,
  businessSales: [],
  businessSalesResponse: {},
  // businessSalesNoApprove: [],
  salePayments: [],
  saleSurplus: [],
  fetchingBusinessActions: false,
  fetchingReports: false,
  reports: [],
  fetchingReportsDetails: false,
  reportsDetails: [],
  searchParamsReports: {},
  fetchingReportsCurrency: false,
  reportsCurrency: [],

  access: [],
}
