import * as types from '../mutations-types'
import {
  fetchBusinessSales,
  fetchBusinessSalesDetail,
  fetchWarehouseConcessionNoApprove,
  createBusinessSales,
  deleteBusinessSalesByCode,
  aproveBusinessSalesByCode,
  updateBusinessSales,
  fetchBusinessSalesPayments,
  fetchBusinessSalesSurplus,
  fetchBusinessSalesNoApprove,
} from '@/api'

export default {
  async fetchBusinessSales ({ commit }, payload) {
    commit(types.FETCH_BUSINESS_SALES_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // console.log('CONCESSION', payload.noApprove)
    const req = payload.noApprove ? await fetchBusinessSalesNoApprove(payload) : await fetchBusinessSales(payload)
    // // // // // // // // // // // // // // // // // // // // // // console.log('REQ', req)
    req.ok
      ? commit(types.FETCH_BUSINESS_SALES_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_BUSINESS_SALES_FAILURE, req)
  },

  async fetchWarehouseConcessionNoApprove ({ commit }, payload) {
    commit(types.FETCH_BUSINESS_SALES_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // console.log('DEVOLUCIONES', payload)
    const req = await fetchWarehouseConcessionNoApprove(payload)
    // // // // // // // // // // // // // // // // // // // // // console.log('REQ', req)
    req.ok
      ? commit(types.FETCH_BUSINESS_SALES_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_BUSINESS_SALES_FAILURE, req)
  },

  async fetchBusinessSalesDetail ({ commit }, payload) {
    commit(types.FETCH_BUSINESS_SALES_DETAIL_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchBusinessSalesDetail(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqMov', req)
    req.ok
      ? commit(types.FETCH_BUSINESS_SALES_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_BUSINESS_SALES_DETAIL_FAILURE, req)
  },

  async fetchBusinessSalesPayments ({ commit }, payload) {
    commit(types.FETCH_BUSINESS_SALES_PAYMENTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchBusinessSalesPayments(payload)
    // // // // // // // // // // // // // // // // // // // // // // console.log('reqPay', req)
    req.ok
      ? commit(types.FETCH_BUSINESS_SALES_PAYMENTS_SUCCESS, req.data.transactionPayments)
      : commit(types.FETCH_BUSINESS_SALES_PAYMENTS_FAILURE, req)
  },

  async fetchBusinessSalesSurplus ({ commit }, payload) {
    commit(types.FETCH_BUSINESS_SALES_SURPLUS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchBusinessSalesSurplus(payload)
    // // // // // // // // // // // // // // // // // // // // // // console.log('reqSupl', req)
    req.ok
      ? commit(types.FETCH_BUSINESS_SALES_SURPLUS_SUCCESS, req.data.transactionPaymentSurplus)
      : commit(types.FETCH_BUSINESS_SALES_SURPLUS_FAILURE, req)
  },

  async createBusinessSales ({ commit, dispatch, state }, payload) {
    commit(types.CREATE_BUSINESS_SALES_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('createMovement', payload)
    const req = await createBusinessSales(payload)
    // // // // // // // // // // // // // // // // // // // console.log('CREATE_CON', req)

    req.ok
      ? commit(types.CREATE_BUSINESS_SALES_SUCCESS, req.data.data.code)
      : commit(types.CREATE_BUSINESS_SALES_FAILURE, req)
    // if (req.ok) {
    //   if (payload.approvate) {
    //     dispatch('aproveBusinessSalesByCode', {
    //     code: req.data.data.code,
    //     approvedByUserId: state.user.id,
    //     })
    //   }
    //   commit(types.CREATE_BUSINESS_SALES_SUCCESS)
    // } else {
    //   // // // // // // // // console.error('ERR', req.response.data.msg)
    //   commit(types.CREATE_BUSINESS_SALES_FAILURE, req.response.data.msg)
    // }
  },

  async deleteBusinessSalesByCode ({ commit }, form) {
    commit(types.DELETE_BUSINESS_SALES_REQUEST)
    const req = await deleteBusinessSalesByCode(form)
    req.ok
      ? commit(types.DELETE_BUSINESS_SALES_SUCCESS)
      : commit(types.DELETE_BUSINESS_SALES_FAILURE, req)
  },

  async aproveBusinessSalesByCode ({ commit }, form) {
    commit(types.APPROVE_BUSINESS_SALES_BY_CODE_REQUEST)
    const req = await aproveBusinessSalesByCode(form)
    // // // // // // // // // // // // // // // // // // // // // // // console.log('REQ_APPROVE', req)
    req.data.ok === true
      ? commit(types.APPROVE_BUSINESS_SALES_BY_CODE_SUCCESS)
      : commit(types.APPROVE_BUSINESS_SALES_BY_CODE_FAILURE, req.data.ok.msg)
  },

  async updateBusinessSales ({ commit }, payload) {
    commit(types.UPDATE_BUSINESS_SALES_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('update', payload)
    const req = await updateBusinessSales(payload)
    req.ok
      ? commit(types.UPDATE_BUSINESS_SALES_SUCCESS)
      : commit(types.UPDATE_BUSINESS_SALES_FAILURE, req)
  },
}
