import * as types from '../mutations-types'

export default {
  [types.FETCH_PRODUCT_CATEGORY_REQUEST] (state) {
    state.fetchingProductCategory = true
  },

  [types.FETCH_PRODUCT_CATEGORY_SUCCESS] (state, productCategory) {
    state.productCategory = productCategory
    state.fetchingProductCategory = false
  },

  [types.FETCH_PRODUCT_CATEGORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingProductCategory = false
  },

  [types.CREATE_PRODUCT_CATEGORY_REQUEST] (state) {
    state.fetchingProductCategory = true
  },

  [types.CREATE_PRODUCT_CATEGORY_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito la categoria de productos'
    state.fetchingProductCategory = false
  },

  [types.CREATE_PRODUCT_CATEGORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductCategory = false
  },

  [types.DELETE_PRODUCT_CATEGORY_REQUEST] (state) {
    state.fetchingProductCategory = true
  },

  [types.DELETE_PRODUCT_CATEGORY_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente la categoria de producto'
    state.fetchingProductCategory = false
  },

  [types.DELETE_PRODUCT_CATEGORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductCategory = false
  },

  [types.ACTIVE_PRODUCT_CATEGORY_REQUEST] (state) {
    state.fetchingProductCategory = true
  },

  [types.ACTIVE_PRODUCT_CATEGORY_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente la categoria de producto'
    state.fetchingProductCategory = false
  },

  [types.ACTIVE_PRODUCT_CATEGORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductCategory = false
  },

  [types.UPDATE_PRODUCT_CATEGORY_REQUEST] (state) {
    state.fetchingProductCategory = true
  },

  [types.UPDATE_PRODUCT_CATEGORY_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito la categoria de producto'
    state.fetchingProductCategory = false
  },

  [types.UPDATE_PRODUCT_CATEGORY_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductCategory = false
  },
}
