<template>
  <div>
    <error-snackbar />
    <success-snackbar />
  </div>
</template>

<script>
  import ErrorSnackbar from './ErrorSnackbar'
  import SuccessSnackbar from './SuccessSnackbar'
  export default {
    name: 'AlertSnackbars',
    components: {
      ErrorSnackbar,
      SuccessSnackbar,
    },
  }
</script>
