import * as types from '../mutations-types'
import {
  fetchProductLine,
  createProductLine,
  deleteProductLine,
  activeProductLine,
  updateProductLine,
} from '@/api'

export default {
  async fetchProductLine ({ commit }, payload) {
    commit(types.FETCH_PRODUCT_LINE_REQUEST)
    const req = await fetchProductLine(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_PRODUCT_LINE_SUCCESS, req.data.productLines)
      : commit(types.FETCH_PRODUCT_LINE_FAILURE, req)
  },

  async createProductLine ({ commit }, payload) {
    commit(types.CREATE_PRODUCT_LINE_REQUEST)
    const req = await createProductLine(payload)
    req.ok
      ? commit(types.CREATE_PRODUCT_LINE_SUCCESS)
      : commit(types.CREATE_PRODUCT_LINE_FAILURE, req)
  },

  async deleteProductLine ({ commit }, { id }) {
    commit(types.DELETE_PRODUCT_LINE_REQUEST)
    const req = await deleteProductLine(id)
    req.ok
      ? commit(types.DELETE_PRODUCT_LINE_SUCCESS)
      : commit(types.DELETE_PRODUCT_LINE_FAILURE, req)
  },

  async activeProductLine ({ commit }, { id }) {
    commit(types.ACTIVE_PRODUCT_LINE_REQUEST)
    const req = await activeProductLine(id)
    req.ok
      ? commit(types.ACTIVE_PRODUCT_LINE_SUCCESS)
      : commit(types.ACTIVE_PRODUCT_LINE_FAILURE, req)
  },

  async updateProductLine ({ commit }, payload) {
    commit(types.UPDATE_PRODUCT_LINE_REQUEST)
    const req = await updateProductLine(payload)
    req.ok
      ? commit(types.UPDATE_PRODUCT_LINE_SUCCESS)
      : commit(types.UPDATE_PRODUCT_LINE_FAILURE, req)
  },
}
