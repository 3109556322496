export const FETCH_BUSINESS_SALES_REQUEST = 'FETCH_BUSINESS_SALES_REQUEST'
export const FETCH_BUSINESS_SALES_SUCCESS = 'FETCH_BUSINESS_SALES_SUCCESS'
export const FETCH_BUSINESS_SALES_FAILURE = 'FETCH_BUSINESS_SALES_FAILURE'

export const FETCH_BUSINESS_SALES_DETAIL_REQUEST = 'FETCH_BUSINESS_SALES_DETAIL_REQUEST'
export const FETCH_BUSINESS_SALES_DETAIL_SUCCESS = 'FETCH_BUSINESS_SALES_DETAIL_SUCCESS'
export const FETCH_BUSINESS_SALES_DETAIL_FAILURE = 'FETCH_BUSINESS_SALES_DETAIL_FAILURE'

export const FETCH_BUSINESS_SALES_PAYMENTS_REQUEST = 'FETCH_BUSINESS_SALES_PAYMENTS_REQUEST'
export const FETCH_BUSINESS_SALES_PAYMENTS_SUCCESS = 'FETCH_BUSINESS_SALES_PAYMENTS_SUCCESS'
export const FETCH_BUSINESS_SALES_PAYMENTS_FAILURE = 'FETCH_BUSINESS_SALES_PAYMENTS_FAILURE'

export const FETCH_BUSINESS_SALES_SURPLUS_REQUEST = 'FETCH_BUSINESS_SALES_SURPLUS_REQUEST'
export const FETCH_BUSINESS_SALES_SURPLUS_SUCCESS = 'FETCH_BUSINESS_SALES_SURPLUS_SUCCESS'
export const FETCH_BUSINESS_SALES_SURPLUS_FAILURE = 'FETCH_BUSINESS_SALES_SURPLUS_FAILURE'

export const CREATE_BUSINESS_SALES_REQUEST = 'CREATE_BUSINESS_SALES_REQUEST'
export const CREATE_BUSINESS_SALES_SUCCESS = 'CREATE_BUSINESS_SALES_SUCCESS'
export const CREATE_BUSINESS_SALES_FAILURE = 'CREATE_BUSINESS_SALES_FAILURE'

export const DELETE_BUSINESS_SALES_REQUEST = 'DELETE_BUSINESS_SALES_REQUEST'
export const DELETE_BUSINESS_SALES_SUCCESS = 'DELETE_BUSINESS_SALES_SUCCESS'
export const DELETE_BUSINESS_SALES_FAILURE = 'DELETE_BUSINESS_SALES_FAILURE'

export const APPROVE_BUSINESS_SALES_BY_CODE_REQUEST = 'APPROVE_BUSINESS_SALES_BY_CODE_REQUEST'
export const APPROVE_BUSINESS_SALES_BY_CODE_SUCCESS = 'APPROVE_BUSINESS_SALES_BY_CODE_SUCCESS'
export const APPROVE_BUSINESS_SALES_BY_CODE_FAILURE = 'APPROVE_BUSINESS_SALES_BY_CODE_FAILURE'

export const UPDATE_BUSINESS_SALES_REQUEST = 'UPDATE_BUSINESS_SALES_REQUEST'
export const UPDATE_BUSINESS_SALES_SUCCESS = 'UPDATE_BUSINESS_SALES_SUCCESS'
export const UPDATE_BUSINESS_SALES_FAILURE = 'UPDATE_BUSINESS_SALES_FAILURE'
