import {
  fetchWarehousesByParentId,
  fetchWarehousesById,
  fetchStoreWarehouses,
  getBusinessByWarehousesId,
  assingWarehouse,
  createWarehouses,
  assingWarehouseParentBusiness,
  updateWarehouse,
  deleteWarehouse,
  activeWarehouse,
  unsetWarehouse,
  getWarehousesInputChartData,
  getWarehousesOutputChartData,
  getWarehousesConcessionedChartData,
} from '../../api'
import * as types from '../mutations-types/index.js'

export default {
  async getWarehousesByParentId ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSES_REQUEST)
    const req = await fetchWarehousesByParentId(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('req', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSES_SUCCESS, req.data.warehousesBusiness)
      : commit(types.FETCH_WAREHOUSES_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getWarehousesById ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSES_BY_ID_REQUEST)
    const req = await fetchWarehousesById(payload)
    // // // // // // // // // // // // // // // // // // // // // // // console.log('req warehouseId', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSES_BY_ID_SUCCESS, req.data.warehouses)
      : commit(types.FETCH_WAREHOUSES_BY_ID_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getStoreWarehouses ({ commit }) {
    commit(types.FETCH_STORE_WAREHOUSES_REQUEST)
    const req = await fetchStoreWarehouses()
    // // // // // // // // // // // // // // // // // // // // // // // console.log('reqWARE', req)
    req.ok
      ? commit(types.FETCH_STORE_WAREHOUSES_SUCCESS, req.data.warehouses)
      : commit(types.FETCH_STORE_WAREHOUSES_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getBusinessByWarehousesId ({ commit }, { id }) {
    commit(types.SET_BUSINESS_BY_WAREHOUSES_REQUEST)
    const req = await getBusinessByWarehousesId(id)
    // // // // // // // // // // // // // // // // // // // // // // // console.log('reqWARE', req)
    req.ok
      ? commit(types.SET_BUSINESS_BY_WAREHOUSES_SUCCESS, req.data.warehouses[0])
      : commit(types.SET_BUSINESS_BY_WAREHOUSES_FAILURE, { message: 'Código de error: ', error: req })
  },

  async assingWarehouses ({ commit }, payload) {
    commit(types.SET_WAREHOUSES_REQUEST)
    const req = await assingWarehouse(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('req', req)
    req.ok
      ? commit(types.SET_WAREHOUSES_SUCCESS, req.data)
      : commit(types.SET_WAREHOUSES_FAILURE, { message: 'Código de error: ', error: req })
  },

  async createWarehouses ({ commit }, data) {
    commit(types.CREATE_WAREHOUSES_REQUEST)
    const req = await createWarehouses(data)

    if (req.ok) {
      // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req.data.data.id)
      const sub = {
        warehouseId: String(req.data.data.id),
        businessId: String(data.businessId),
      }

      // // // // // // // // // // // // // // // // // // // // // // // // // console.log(sub)

      const res = await assingWarehouseParentBusiness(sub)
      // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ware', res)
      res.ok
        ? commit(types.CREATE_WAREHOUSES_SUCCESS)
        : commit(types.CREATE_WAREHOUSES_FAILURE, req)
    } else {
      commit(types.CREATE_WAREHOUSES_FAILURE, req)
    }
  },

  async updateWarehouse ({ commit }, data) {
    commit(types.UPDATE_WAREHOUSES_REQUEST)
    const req = await updateWarehouse(data)
    req.ok
      ? commit(types.UPDATE_WAREHOUSES_SUCCESS)
      : commit(types.UPDATE_WAREHOUSES_FAILURE, req)
  },

  async deleteWarehouse ({ commit }, { id }) {
    commit(types.DELETE_WAREHOUSES_REQUEST)
    const req = await deleteWarehouse(id)
    req.ok
      ? commit(types.DELETE_WAREHOUSES_SUCCESS)
      : commit(types.DELETE_WAREHOUSES_FAILURE, req)
  },

  async activeWarehouse ({ commit }, { id }) {
    commit(types.ACTIVATE_WAREHOUSES_REQUEST)
    const req = await activeWarehouse(id)
    req.ok
      ? commit(types.ACTIVATE_WAREHOUSES_SUCCESS)
      : commit(types.ACTIVATE_WAREHOUSES_FAILURE, req)
  },

  async deactiveAuthWarehouse ({ commit }, { id }) {
    commit(types.DEACTIVATE_WAREHOUSES_REQUEST)
    const req = await unsetWarehouse(id)
    req.ok
      ? commit(types.DEACTIVATE_WAREHOUSES_SUCCESS)
      : commit(types.DEACTIVATE_WAREHOUSES_FAILURE, req)
  },

  async getWarehousesInputChartData ({ commit }, warehouseId) {
    commit(types.GET_WAREHOUSES_INPUT_CHART_DATA_REQUEST)
    const req = await getWarehousesInputChartData(warehouseId)
    req.ok
      ? commit(types.GET_WAREHOUSES_INPUT_CHART_DATA_SUCCESS, req.data)
      : commit(types.GET_WAREHOUSES_INPUT_CHART_DATA_FAILURE, req)
  },

  async getWarehousesOutputChartData ({ commit }, warehouseId) {
    commit(types.GET_WAREHOUSES_OUTPUT_CHART_DATA_REQUEST)
    const req = await getWarehousesOutputChartData(warehouseId)
    req.ok
      ? commit(types.GET_WAREHOUSES_OUTPUT_CHART_DATA_SUCCESS, req.data)
      : commit(types.GET_WAREHOUSES_OUTPUT_CHART_DATA_FAILURE, req)
  },

  async getWarehousesConcessionedChartData ({ commit }, warehouseId) {
    commit(types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_REQUEST)
    const req = await getWarehousesConcessionedChartData(warehouseId)
    req.ok
      ? commit(types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_SUCCESS, req.data)
      : commit(types.GET_WAREHOUSES_CONCESSIONED_CHART_DATA_FAILURE, req)
  },
}
