import loginActions from './actions/login'
import welcomeActions from './actions/welcome'
import usersActions from './actions/users'
import miscsActions from './actions/misc'
import signupActions from './actions/signup'
import recoverPassword from './actions/recover-password'
import setPassword from './actions/set-password'
import business from './actions/business'
import currencies from './actions/currencies'
import adminStoreActions from './actions/admin-store'
import warehouses from './actions/warehouses'
import adminWarehousesTypesActions from './actions/admin-warehouses-types'
import productLine from './actions/product-line'
import productClassification from './actions/product-classification'
import productCategory from './actions/product-category'
import productType from './actions/product-type'
import products from './actions/products'
import warehouseInputs from './actions/warehouse-inputs'
import warehouseOutputs from './actions/warehouse-outputs'
import warehouseMovements from './actions/warehouse-movements'
import warehouseInventory from './actions/warehouse-inventory'
import bulkLoad from './actions/bulk-load'
import productsBulkLoad from './actions/products-bulk-load'
import clients from './actions/clients'
import warehouseConcessions from './actions/warehouse-concessions'
import paymentsMethods from './actions/payments-methods'
import warehousesMovementsBulkLoad from './actions/warehouses-movements-bulk-load'
import transactionsBulkLoad from './actions/transactions-bulk-load'
import businessSales from './actions/business-sales'
import reports from './actions/reports'
import providers from './actions/providers'

export default {
  ...loginActions,
  ...welcomeActions,
  ...usersActions,
  ...miscsActions,
  ...signupActions,
  ...recoverPassword,
  ...setPassword,
  ...business,
  ...currencies,
  ...adminStoreActions,
  ...warehouses,
  ...adminWarehousesTypesActions,
  ...productLine,
  ...productClassification,
  ...productCategory,
  ...productType,
  ...products,
  ...warehouseInputs,
  ...warehouseOutputs,
  ...warehouseMovements,
  ...warehouseConcessions,
  ...warehouseInventory,
  ...bulkLoad,
  ...productsBulkLoad,
  ...clients,
  ...paymentsMethods,
  ...warehousesMovementsBulkLoad,
  ...transactionsBulkLoad,
  ...businessSales,
  ...reports,
  ...providers,
}
