import axios from 'axios'

export const fetchWarehousesTypes = async (id) => {
  return axios.get('/api/v1/warehouseTypes/getBusinessId/' + id)
}

export const createWarehousesTypes = async (businessId, name) => {
  return axios.post('/api/v1/warehouseTypes', {
    businessId,
    name,
  })
}

export const deleteWarehouseType = async id => {
  return await axios.post(`/api/v1/warehouseTypes/deactive/${id}`)
}

export const activeWarehouseType = async id => {
  return await axios.post(`/api/v1/warehouseTypes/active/${id}`)
}

export const updateWarehouseType = async (id, { name, businessId }) => {
  return await axios.put(`/api/v1/warehouseTypes/${id}`, {
    name,
    businessId,
  })
}
