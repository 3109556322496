import axios from 'axios'
import { DateTime } from 'luxon'
import { TYPE_TRANSACTION } from '../../const'

export const fetchParentsBusiness = async () => {
  return await axios.get('/api/v1/business/parents')
}

export const fetchParentsBusinessById = async ({ id }) => {
  return await axios.get('/api/v1/business/parents/' + id)
}

export const fetchChildsBusiness = async ({ id }) => {
  return await axios.get('/api/v1/business/parents/child/' + id)
}

export const getBusinessChartData = async ({ warehouseId }) => {
  return await axios.get('/api/v1/transactions/countByFilter', {
    params: {
      warehouseId,
      approved: 1,
      typeTransactionId: TYPE_TRANSACTION.venta_por_concesion,
      start: DateTime.now().minus({ days: 6 }).toFormat(
        'yyyy-MM-dd',
      ),
    },
  })
}
