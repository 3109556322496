import axios from 'axios'
// import { TYPE_TRANSACTION } from '../../const'

export const fetchWarehouseConcessions = async ({
  id,
  start,
  end,
  approved,
  relatedWarehouseId,
  businessId,
}) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  // return axios.get('/api/v1/products/getBusinessId/' + id)
  return axios.get('/api/v1/transactions/getConcessionTransactionsWarehousesId/' + id, {
    params: {
      start,
      end,
      approved,
      relatedWarehouseId,
      businessId,
    },
  })
}

export const fetchWarehouseConcessionsDetail = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const createWarhouseConcessions = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/transactions', payload)
}

export const deleteWarhouseConcessionsByCode = async form => {
  return await axios.post('/api/v1/transactions/deactiveByCode', form)
}

export const aproveWarhouseConcessionsByCode = async (form) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('formu', form)
  return await axios.post('/api/v1/transactions/approvedByCode', form)
}

export const deleteWarhouseConcessionsById = async ({ id }) => {
  return await axios.post('/api/v1/transactions/deactive/' + id)
}

export const aproveWarhouseConcessionsById = async (form) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('formu', form)
  return await axios.post('/api/v1/transactions/approvedById', form)
}

export const updateWarhouseConcessions = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
