import { load as bulkLoad } from '../../api'
import * as types from '../mutations-types'

export default {
  async bulkLoad ({ commit }, { formData }) {
    commit(types.BULK_LOAD_REQUEST)
    const req = await bulkLoad(formData)
    req.ok
      ? commit(types.BULK_LOAD_SUCCESS, req.data)
      : commit(types.BULK_LOAD_FAILURE, req)
  },
}
