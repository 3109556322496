import * as types from '../mutations-types'

export default {
  [types.FETCH_PRODUCT_TYPE_REQUEST] (state) {
    state.fetchingProductType = true
  },

  [types.FETCH_PRODUCT_TYPE_SUCCESS] (state, productType) {
    state.productType = productType
    state.fetchingProductType = false
  },

  [types.FETCH_PRODUCT_TYPE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingProductType = false
  },

  [types.CREATE_PRODUCT_TYPE_REQUEST] (state) {
    state.fetchingProductType = true
  },

  [types.CREATE_PRODUCT_TYPE_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito el tipo de productos'
    state.fetchingProductType = false
  },

  [types.CREATE_PRODUCT_TYPE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductType = false
  },

  [types.DELETE_PRODUCT_TYPE_REQUEST] (state) {
    state.fetchingProductType = true
  },

  [types.DELETE_PRODUCT_TYPE_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el tipo de producto'
    state.fetchingProductType = false
  },

  [types.DELETE_PRODUCT_TYPE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductType = false
  },

  [types.ACTIVE_PRODUCT_TYPE_REQUEST] (state) {
    state.fetchingProductType = true
  },

  [types.ACTIVE_PRODUCT_TYPE_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el tipo de producto'
    state.fetchingProductType = false
  },

  [types.ACTIVE_PRODUCT_TYPE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductType = false
  },

  [types.UPDATE_PRODUCT_TYPE_REQUEST] (state) {
    state.fetchingProductType = true
  },

  [types.UPDATE_PRODUCT_TYPE_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el tipo de producto'
    state.fetchingProductType = false
  },

  [types.UPDATE_PRODUCT_TYPE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingProductType = false
  },
}
