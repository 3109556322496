import * as types from '../mutations-types'

export default {
  [types.CHECK_WM_BULK_LOAD_FILE_REQUEST] (state) {
    state.checkingWmBulkRequestFile = true
  },
  [types.CHECK_WM_BULK_LOAD_FILE_SUCCESS] (state, req) {
    state.checkingWmBulkRequestFile = false
    state.wmBulkRequestCheckingResult = req
  },
  [types.CHECK_WM_BULK_LOAD_FILE_FAILURE] (state, req) {
    state.checkingWmBulkRequestFile = false
    state.wmBulkRequestCheckingResult = req
  },

  [types.LOAD_WM_BULK_LOAD_FILE_REQUEST] (state) {
    state.loadingWmBulkRequestFile = true
  },
  [types.LOAD_WM_BULK_LOAD_FILE_SUCCESS] (state, req) {
    state.loadingWmBulkRequestFile = false
    state.loadWmBulkRequestResult = req
  },
  [types.LOAD_WM_BULK_LOAD_FILE_FAILURE] (state, req) {
    state.loadingWmBulkRequestFile = false
    state.loadWmBulkRequestResult = req
  },
}
