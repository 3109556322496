import axios from 'axios'

export const createClients = async (form) => {
  return await axios.post('/api/v1/clients/', form)
}

export const updateClient = async form => {
  return await axios.patch(`/api/v1/clients/${form.id}`, form)
}
export const activeClient = async form => {
  return await axios.patch(`/api/v1/clients/active/${form.id}`, form)
}
export const deactiveClient = async form => {
  return await axios.patch(`/api/v1/clients/deactive/${form.id}`, form)
}

export const deleteClient = async form => {
  return await axios.delete(`/api/v1/clients/${form.id}`)
}

export const fetchClients = async (form) => {
  return await axios.get('/api/v1/clients/getOneByCi/' + form)
}

export const fetchAllClients = async (form) => {
  return await axios.get('/api/v1/clients/')
}

export const fetchClientsById = async id => {
  return await axios.get('/api/v1/clients/' + id)
}
