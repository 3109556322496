import * as types from '../mutations-types'

export default {
  [types.FETCH_WAREHOUSES_TYPES_REQUEST] (state) {
    state.fetchingWarehousesTypes = true
  },

  [types.FETCH_WAREHOUSES_TYPES_SUCCESS] (state, warehousesTypes) {
    state.warehousesTypes = warehousesTypes
    state.fetchingWarehousesTypes = false
  },

  [types.FETCH_WAREHOUSES_TYPES_FAILURE] (state, error) {
    // // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehousesTypes = false
  },

  [types.CREATE_WAREHOUSES_TYPES_REQUEST] (state) {
    state.creatingWarehousesTypes = true
  },

  [types.CREATE_WAREHOUSES_TYPES_SUCCESS] (state) {
    state.success = 'Se ha registrado con exito el tipo de almacen'
    state.creatingWarehousesTypes = false
  },

  [types.CREATE_WAREHOUSES_TYPES_FAILURE] (state, error) {
    // // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.creatingWarehousesTypes = false
  },

  [types.DELETE_WAREHOUSES_TYPES_REQUEST] (state) {
    state.deactivingWarehouseType = true
  },

  [types.DELETE_WAREHOUSES_TYPES_SUCCESS] (state) {
    state.success = 'Se ha desactivado correctamente el tipo de almacen'
    state.deactivingWarehouseType = false
  },

  [types.DELETE_WAREHOUSES_TYPES_FAILURE] (state, error) {
    // // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.deactivingWarehouseType = false
  },

  [types.ACTIVE_WAREHOUSES_TYPES_REQUEST] (state) {
    state.activingWarehouseType = true
  },

  [types.ACTIVE_WAREHOUSES_TYPES_SUCCESS] (state) {
    state.success = 'Se ha activado correctamente el tipo de almacen'
    state.activingWarehouseType = false
  },

  [types.ACTIVE_WAREHOUSES_TYPES_FAILURE] (state, error) {
    // // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.activingWarehouseType = false
  },

  [types.UPDATE_WAREHOUSES_TYPES_REQUEST] (state) {
    state.updatingWarehouseType = true
  },

  [types.UPDATE_WAREHOUSES_TYPES_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el tipo de almacen'
    state.updatingWarehouseType = false
  },

  [types.UPDATE_WAREHOUSES_TYPES_FAILURE] (state, error) {
    // // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.updatingWarehouseType = false
  },
}
