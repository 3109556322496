import * as types from '../mutations-types'

export default {
  [types.CHANGE_PARAMS] (state, params) {
    state.searchParamsConcessions = {
      ...state.searchParamsConcessions,
      ...params,
    }
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_SUCCESS] (state, warehousesConcessions) {
    state.warehousesConcessions = warehousesConcessions
    state.fetchingWarehouseConcessions = false
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_REQUEST] (state) {
    state.fetchingWarehouseConcessionsDetail = true
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_SUCCESS] (state, warhouseConcessionsDetail) {
    state.warhouseConcessionsDetail = warhouseConcessionsDetail
    state.fetchingWarehouseConcessionsDetail = false
  },

  [types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingWarehouseConcessionsDetail = false
  },

  [types.CREATE_WAREHOUSE_CONCESSIONS_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.CREATE_WAREHOUSE_CONCESSIONS_SUCCESS] (state) {
    state.success = 'Se ha realizado la operacion con exito'
    state.fetchingWarehouseConcessions = false
  },

  [types.CREATE_WAREHOUSE_CONCESSIONS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_SUCCESS] (state) {
    state.success = 'Se ha eliminado correctamente la solicitud'
    state.fetchingWarehouseConcessions = false
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_SUCCESS] (state) {
    state.success = 'Se ha aprobado correctamente la solicitud'
    state.fetchingWarehouseConcessions = false
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS] (state) {
    state.success = 'Se ha eliminado correctamente la solicitud'
    state.fetchingWarehouseConcessions = false
  },

  [types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST] (state) {
    state.fetchingBusinessActions = true
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS] (state) {
    state.success = 'Se ha aprobado correctamente la solicitud'
    state.fetchingWarehouseConcessions = false
  },

  [types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },

  [types.UPDATE_WAREHOUSE_CONCESSIONS_REQUEST] (state) {
    state.fetchingWarehouseConcessions = true
  },

  [types.UPDATE_WAREHOUSE_CONCESSIONS_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingWarehouseConcessions = false
  },

  [types.UPDATE_WAREHOUSE_CONCESSIONS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingWarehouseConcessions = false
  },
}
