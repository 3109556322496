import * as types from '../mutations-types/index.js'

export default {
  [types.SET_PASSWORD_REQUEST] (state) {
    state.fetchingResetPassword = true
  },

  [types.SET_PASSWORD_SUCCESS] (state, loginData) {
    state.fetchingResetPassword = false
    state.success = 'Se a cambiado su contraseña satisfactoriamente'
  },

  [types.SET_PASSWORD_FAILURE] (state, req) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req)
    state.fetchingResetPassword = false
    state.error = `Error al intentar realizar el envio de correo, código de error: ${req.response.data.message}`
  },
}
