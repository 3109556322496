import * as types from '../mutations-types'

export default {
  [types.CHANGE_PARAMS] (state, params) {
    state.searchParamsConcessions = {
      ...state.searchParamsConcessions,
      ...params,
    }
  },

  [types.FETCH_REPORTS_REQUEST] (state) {
    state.fetchingReports = true
  },

  [types.FETCH_REPORTS_SUCCESS] (state, reports) {
    state.reports = reports
    state.fetchingReports = false
  },

  [types.FETCH_REPORTS_FAILURE] (state, error) {
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingReports = false
  },

  [types.FETCH_REPORTS_DETAIL_REQUEST] (state) {
    state.fetchingReportsDetails = true
  },

  [types.FETCH_REPORTS_DETAIL_SUCCESS] (state, reportsDetails) {
    state.reportsDetails = reportsDetails
    state.fetchingReportsDetails = false
  },

  [types.FETCH_REPORTS_DETAIL_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingReportsDetails = false
  },

  [types.FETCH_REPORTS_CURRENCY_REQUEST] (state) {
    state.fetchingReportsCurrency = true
  },

  [types.FETCH_REPORTS_CURRENCY_SUCCESS] (state, reportsCurrency) {
    state.reportsCurrency = reportsCurrency
    state.fetchingReportsCurrency = false
  },

  [types.FETCH_REPORTS_CURRENCY_FAILURE] (state, error) {
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingReportsCurrency = false
  },

}
