import * as types from '../mutations-types'
import {
  fetchWarehouseInventory,
  fetchStoreInventory,
} from '@/api'

export default {
  async fetchWarehouseInventory ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_INVENTORY_REQUEST)
    // // // console.log('payMov', payload)
    const req = await fetchWarehouseInventory(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqInventory', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_INVENTORY_SUCCESS, req.data.WarehouseInventory)
      : commit(types.FETCH_WAREHOUSE_INVENTORY_FAILURE, req)
  },

  async fetchStoreInventory ({ commit }, payload) {
    commit(types.FETCH_STORE_INVENTORY_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('payMov', payload)
    const req = await fetchStoreInventory(payload)
    // // // // // // // // // // // // // // // // // // // // // // // console.log('reqInventory', req)
    req.ok
      ? commit(types.FETCH_STORE_INVENTORY_SUCCESS, req.data.StoreInventory)
      : commit(types.FETCH_STORE_INVENTORY_FAILURE, req)
  },
}
