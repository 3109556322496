import axios from 'axios'

export const fetchCurrencies = async ({ id }) => {
  return await axios.get('/api/v1/currencies/by_business?businessId=' + id)
}

export const updateCurrencies = async (form) => {
  // // // // // // // // // // // // // // // // // // // // // // // // // console.log(form)
  // // // // // // // // // // // // // // // // // // // // // // // // // console.log({
  //   name: form.name,
  //   symbol: form.symbol,
  //   value: Number(form.value),
  //   isReference: form.isReference === 1,
  //   businessId: form.businessId,
  //   status: form.status === 1,
  // })
  return await axios.put('/api/v1/currencies/update/' + form.id, {
    name: form.name,
    symbol: form.symbol,
    value: Number(form.value),
    isReference: form.isReference === 1,
    businessId: form.businessId,
    status: form.status === 1,

  })
}
