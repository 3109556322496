import * as types from '../mutations-types'
import {
  fetchProducts,
  createProducts,
  deleteProducts,
  activeProducts,
  updateProducts,
} from '@/api'

export default {
  async fetchProducts ({ commit }, payload) {
    commit(types.FETCH_PRODUCT_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchProducts(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqProd', req)
    req.ok
      ? commit(types.FETCH_PRODUCT_SUCCESS, req.data.products)
      : commit(types.FETCH_PRODUCT_FAILURE, req)
  },

  async createProducts ({ commit }, payload) {
    commit(types.CREATE_PRODUCT_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // console.log('createProducts', payload)
    const req = await createProducts(payload)
    req.ok
      ? commit(types.CREATE_PRODUCT_SUCCESS)
      : commit(types.CREATE_PRODUCT_FAILURE, req)
  },

  async deleteProducts ({ commit }, { id }) {
    commit(types.DELETE_PRODUCT_REQUEST)
    const req = await deleteProducts(id)
    req.ok
      ? commit(types.DELETE_PRODUCT_SUCCESS)
      : commit(types.DELETE_PRODUCT_FAILURE, req)
  },

  async activeProducts ({ commit }, { id }) {
    commit(types.ACTIVE_PRODUCT_REQUEST)
    const req = await activeProducts(id)
    req.ok
      ? commit(types.ACTIVE_PRODUCT_SUCCESS)
      : commit(types.ACTIVE_PRODUCT_FAILURE, req)
  },

  async updateProducts ({ commit }, payload) {
    commit(types.UPDATE_PRODUCT_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('update', payload)
    const req = await updateProducts(payload)
    req.ok
      ? commit(types.UPDATE_PRODUCT_SUCCESS)
      : commit(types.UPDATE_PRODUCT_FAILURE, req)
  },
}
