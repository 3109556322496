import * as types from '../mutations-types'
import {
  fetchParentChilds,
  createBusiness,
  deactiveBusiness,
  updateBusiness,
} from '../../api'

export default {
  async getParentChilds ({ commit }, businessId) {
    commit(types.FETCH_PARENT_CHILDS_REQUEST)
    const req = await fetchParentChilds(businessId)
    req.ok
      ? commit(types.FETCH_PARENT_CHILDS_SUCCESS, req.data.business)
      : commit(types.FETCH_PARENT_CHILDS_FAILURE, req)
  },

  async createBusiness ({ commit }, data) {
    commit(types.CREATE_BUSINESS_REQUEST)
    const req = await createBusiness(data)
    req.ok
      ? commit(types.CREATE_BUSINESS_SUCCESS)
      : commit(types.CREATE_BUSINESS_FAILURE, req)
  },

  async deactiveBusiness ({ commit }, id) {
    commit(types.DEACTIVE_BUSINESS_REQUEST)
    const req = await deactiveBusiness(id)
    req.ok
      ? commit(types.DEACTIVE_BUSINESS_SUCCESS)
      : commit(types.DEACTIVE_BUSINESS_FAILURE, req)
  },

  async updateBusiness ({ commit }, { id, data }) {
    commit(types.UPDATE_BUSINESS_REQUEST)
    const req = await updateBusiness(id, data)
    req.ok
      ? commit(types.UPDATE_BUSINESS_SUCCESS)
      : commit(types.UPDATE_BUSINESS_FAILURE, req)
  },
}
