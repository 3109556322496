import axios from 'axios'

export const setPassword = async ({ oldPassword, password, token }) => {
  if (token) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    }
  }

  const req = await axios.put('/api/v1/users/set_password', {
    oldPassword,
    password,
  })

  if (token) {
    axios.defaults.headers.common = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }
  return req
}
