import * as types from '../mutations-types'
import {
  transactionsBulkLoadLoadFile,
  transactionsBulkLoadCheckFile,
} from '../../api'

export default {
  async transactionsBulkLoadCheckFile ({ commit }, formData) {
    commit(types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_REQUEST)
    const req = await transactionsBulkLoadCheckFile(formData)
    req.ok
      ? commit(types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_SUCCESS, req.data)
      : commit(types.CHECK_TRANSACTIONS_BULK_LOAD_FILE_FAILURE, req.response.data)
  },
  async transactionsBulkLoadLoadFile ({ commit }, formData) {
    commit(types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_REQUEST)
    const req = await transactionsBulkLoadLoadFile(formData)
    req.ok
      ? commit(types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_SUCCESS, req.data)
      : commit(types.LOAD_TRANSACTIONS_BULK_LOAD_FILE_FAILURE, req.response.data)
  },
}
