<template>
  <v-app>
    <alert-snackbars />
    <router-view />
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex'
  import AlertSnackbars from '@/components/AlertSnackbars'
  export default {
    name: 'App',
    components: {
      AlertSnackbars,
    },
    async created () {
      if (localStorage.getItem('token') && window.location.pathname !== '/close-session') {
        // await this.getUserByToken()
        this.$router.push('/close-session')
      }
    },
    methods: {
      ...mapActions([
        'getUserByToken',
      ]),
    },
  }
</script>
