import * as types from '../mutations-types'
import {
  fetchWarehouseConcessions,
  fetchWarehouseConcessionsDetail,
  createWarhouseConcessions,
  deleteWarhouseConcessionsByCode,
  aproveWarhouseConcessionsByCode,
  updateWarhouseConcessions,
  deleteWarhouseConcessionsById,
  aproveWarhouseConcessionsById,
} from '@/api'

export default {
  async fetchWarehouseConcessions ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_CONCESSIONS_REQUEST)
    // // // // // // // // // // console.log('CONCESSION', payload)
    const req = await fetchWarehouseConcessions(payload)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_CONCESSIONS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_CONCESSIONS_FAILURE, req)
  },

  async fetchWarehouseConcessionsDetail ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_REQUEST)
    const req = await fetchWarehouseConcessionsDetail(payload)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_CONCESSIONS_DETAIL_FAILURE, req)
  },

  async createWarehouseConcessions ({ commit, dispatch, state }, payload) {
    commit(types.CREATE_WAREHOUSE_CONCESSIONS_REQUEST)
    const req = await createWarhouseConcessions(payload)
    if (req.ok) {
      commit(types.CREATE_WAREHOUSE_CONCESSIONS_SUCCESS)
    } else {
      commit(types.CREATE_WAREHOUSE_CONCESSIONS_FAILURE, req.response.data.msg)
    }
  },

  async deleteWarhouseConcessionsByCode ({ commit }, form) {
    commit(types.DELETE_WAREHOUSE_CONCESSIONS_REQUEST)
    const req = await deleteWarhouseConcessionsByCode(form)
    req.ok
      ? commit(types.DELETE_WAREHOUSE_CONCESSIONS_SUCCESS)
      : commit(types.DELETE_WAREHOUSE_CONCESSIONS_FAILURE, req)
  },

  async aproveWarhouseConcessionsByCode ({ commit }, form) {
    commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_REQUEST)
    const req = await aproveWarhouseConcessionsByCode(form)
    req.data.ok === true
      ? commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_SUCCESS)
      : commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_FAILURE, req.data.ok.msg)
  },

  async deleteWarhouseConcessionsById ({ commit }, form) {
    commit(types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST)
    const req = await deleteWarhouseConcessionsById(form)
    req.ok
      ? commit(types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS)
      : commit(types.DELETE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE, req)
  },

  async aproveWarhouseConcessionsById ({ commit }, form) {
    commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST)
    const req = await aproveWarhouseConcessionsById(form)
    req.data.ok === true
      ? commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS)
      : commit(types.APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE, req.data.ok.msg)
  },

  async updateWarhouseConcessions ({ commit }, payload) {
    commit(types.UPDATE_WAREHOUSE_CONCESSIONS_REQUEST)
    const req = await updateWarhouseConcessions(payload)
    req.ok
      ? commit(types.UPDATE_WAREHOUSE_CONCESSIONS_SUCCESS)
      : commit(types.UPDATE_WAREHOUSE_CONCESSIONS_FAILURE, req)
  },
}
