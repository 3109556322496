import axios from 'axios'

export const signup = async ({ name, email, password, role }) => {
  return await axios.post('/api/v1/users/register', {
    name,
    email,
    password,
    role,
    enable: true,
  })
}
