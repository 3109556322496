export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST'
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS'
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE'

export const CREATE_PRODUCT_CATEGORY_REQUEST = 'CREATE_PRODUCT_CATEGORY_REQUEST'
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS'
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE'

export const DELETE_PRODUCT_CATEGORY_REQUEST = 'DELETE_PRODUCT_CATEGORY_REQUEST'
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS'
export const DELETE_PRODUCT_CATEGORY_FAILURE = 'DELETE_PRODUCT_CATEGORY_FAILURE'

export const ACTIVE_PRODUCT_CATEGORY_REQUEST = 'ACTIVE_PRODUCT_CATEGORY_REQUEST'
export const ACTIVE_PRODUCT_CATEGORY_SUCCESS = 'ACTIVE_PRODUCT_CATEGORY_SUCCESS'
export const ACTIVE_PRODUCT_CATEGORY_FAILURE = 'ACTIVE_PRODUCT_CATEGORY_FAILURE'

export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'UPDATE_PRODUCT_CATEGORY_REQUEST'
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS'
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE'
