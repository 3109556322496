import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/404',
      component: () => import('@/views/not-found/Index'),
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      name: 'Forbidden',
      path: '/forbidden',
      component: () => import('@/views/forbidden/Index'),
    },
    {
      name: 'Unauthorized',
      path: '/unauthorized',
      component: () => import('@/views/unauthorized/Index'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Bulk Upload',
          path: 'bulk_upload',
          component: () => import('@/views/bulk-upload/Index'),
        },
        {
          path: '',
          redirect: { name: 'Login' },
        },
        {
          name: 'Admin',
          path: '',
          redirect: { name: 'Dashboard' },
        },
        // Dashboard
        {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'BusinessDashboard',
          path: '/adminis/business_/:id',
          component: () => import('@/views/dashboard/pages/BusinessDashboardView'),
        },
        {
          name: 'WarehouseDashboard',
          path: '/admini/warehouses/:id',
          component: () => import('@/views/dashboard/pages/WarehousesDashboardView'),
        },
        {
          name: 'DashboardChild',
          path: '/business/childs/:id',
          component: () => import('@/views/dashboard/pages/DashboardChilds'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Products bulk load',
          path: '/admin/products/bulk-load',
          component: () => import('@/views/dashboard/pages/ProductsBulkLoad'),
        },
        {
          name: 'Transactions bulk load',
          path: '/admin/transactions/bulk-load',
          component: () => import('@/views/dashboard/pages/WarehousesMovementsBulkLoad'),
        },
        {
          name: 'Transactions bulk load for store',
          path: '/adminis/transactions/store/bulk-load/:id',
          component: () => import('@/views/dashboard/pages/TransactionsBulkLoad'),
        },
        {
          name: 'InfoBusiness',
          path: '/admin/info-business/:id',
          component: () => import('@/views/dashboard/pages/InfoBusiness'),
        },
        {
          name: 'Currencies',
          path: '/admin/currencies',
          component: () => import('@/views/dashboard/pages/AdminCurrencies'),
        },
        {
          name: 'Admin User',
          path: '/admin/users',
          component: () => import('@/views/dashboard/pages/AdminUsers'),
        },
        {
          name: 'Admin Clients',
          path: '/admin/clients',
          component: () => import('@/views/dashboard/pages/AdminClients'),
        },
        {
          name: 'Admin Providers',
          path: '/admin/providers',
          component: () => import('@/views/dashboard/pages/AdminProviders'),
        },
        {
          name: 'Admin Store',
          path: '/admin/store',
          component: () => import('@/views/dashboard/pages/AdminStore'),
        },
        {
          name: 'Admin Warehouses Types',
          path: '/admin/warehouses_types',
          component: () => import('@/views/dashboard/pages/AdminWarehousesTypes'),
        },
        {
          name: 'Admin Warehouses',
          path: '/admin/warehouses',
          component: () => import('@/views/dashboard/pages/AdminWarehouses'),
        },
        {
          name: 'Admin Product Line',
          path: '/admin/product-line',
          component: () => import('@/views/dashboard/pages/ProductLine'),
        },
        {
          name: 'Admin Product Classification',
          path: '/admin/product-classification',
          component: () => import('@/views/dashboard/pages/ProductClassification'),
        },
        {
          name: 'Admin Product Category',
          path: '/admin/product-category',
          component: () => import('@/views/dashboard/pages/ProductCategory'),
        },
        {
          name: 'Admin Product Type',
          path: '/admin/product-type',
          component: () => import('@/views/dashboard/pages/ProductType'),
        },
        {
          name: 'Admin Products',
          path: '/admin/products',
          component: () => import('@/views/dashboard/pages/Products'),
        },
        {
          name: 'Admin Reports',
          path: '/admin/reports',
          component: () => import('@/views/dashboard/pages/Reports'),
        },
        {
          name: 'Admin Reports By Currency',
          path: '/admin/reports-currency',
          component: () => import('@/views/dashboard/pages/ReportsCurrency'),
        },
        {
          name: 'Warehouses Inputs',
          path: '/admini/warehouse/inputs/:id',
          component: () => import('@/views/dashboard/pages/WarehousesInputs'),
        },
        {
          name: 'Warehouses Outputs',
          path: '/admini/warehouse/outputs/:id',
          component: () => import('@/views/dashboard/pages/WarehousesOutputs'),
        },
        {
          name: 'Warehouses Movements',
          path: '/admini/warehouse/movements/:id',
          component: () => import('@/views/dashboard/pages/WarehousesMovements'),
        },
        {
          name: 'Warehouses Concessions',
          path: '/admini/warehouse/concessions/:id',
          component: () => import('@/views/dashboard/pages/WarehousesConcessions'),
        },
        {
          name: 'Warehouse Returns',
          path: '/admini/warehouse/returns/:id',
          component: () => import('@/views/dashboard/pages/WarehouseReturns'),
        },
        {
          name: 'Warehouse Reports',
          path: '/admini/warehouse/reports/:id',
          component: () => import('@/views/dashboard/pages/WarehouseReports'),
        },
        {
          name: 'Business Reports',
          path: '/adminis/business/reports/:id',
          component: () => import('@/views/dashboard/pages/BusinessReports'),
        },
        {
          name: 'Concession Request',
          path: '/admin/business/concession-request/:id',
          component: () => import('@/views/dashboard/pages/BusinessConcessionRequest'),
        },
        {
          name: 'Business Sales',
          path: '/admin/business/sales/:id',
          component: () => import('@/views/dashboard/pages/BusinessSales'),
        },
        {
          name: 'Business Returns',
          path: '/adminis/business/returns/:id',
          component: () => import('@/views/dashboard/pages/BusinessReturns'),
        },
        {
          name: 'Business Sales No Approvate',
          path: '/admin/business/sales-no-approvate',
          component: () => import('@/views/dashboard/pages/BusinessSalesNoApprovate'),
        },
        {
          name: 'New Sale',
          path: '/adminis/business/new-sale/:id',
          component: () => import('@/views/dashboard/pages/BusinessNewSale'),
        },
        {
          name: 'Principal Inventory',
          path: '/admin/business/pricipal-inventory/:id',
          component: () => import('@/views/dashboard/pages/PrincipalInventory'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/login/Index'),
    },
    {
      name: 'RecoverPassword',
      path: '/recover',
      component: () => import('@/views/recover-password/Index'),
    },
    {
      name: 'CloseSession',
      path: '/close-session',
      component: () => import('@/views/close-session/Index'),
    },
  ],
})

/**
 * Routes protection
 */

// Routes login not required
const whiteList = [
  'Home',
  'Login',
  // 'Signup',
  'RecoverPassword',
  'Forbidden',
  'Unauthorized',
]

// Routes redirect to dashboard if the user is logged
const authenticationPages = [
  'Login',
  // 'Signup',
  'RecoverPassword',
]

router.beforeEach((to, _from, next) => {
  if (authenticationPages.includes(to.name) && localStorage.getItem('token')) {
    return next('/dashboard')
  }
  if (!to.matched.length) {
    next('/404')
  }
  if (!whiteList.includes(to.name) && !localStorage.getItem('token')) {
    next('/unauthorized')
  } else {
    next()
  }
})

export default router
