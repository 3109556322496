import axios from 'axios'

export const fetchProducts = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/products/getBusinessId/' + id)
}

export const createProducts = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/products', payload)
}

export const deleteProducts = async id => {
  return await axios.post(`/api/v1/products/deactive/${id}`)
}

export const activeProducts = async id => {
  return await axios.post(`/api/v1/products/active/${id}`)
}

export const updateProducts = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
