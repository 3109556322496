import axios from 'axios'

export const fetchProductType = async ({ id }) => {
  return axios.get('/api/v1/productTypes/getBusinessId/' + id)
}

export const createProductType = async ({ businessId, name }) => {
  return axios.post('/api/v1/productTypes', {
    businessId,
    description: name,
  })
}

export const deleteProductType = async id => {
  return await axios.post(`/api/v1/productTypes/deactive/${id}`)
}

export const activeProductType = async id => {
  return await axios.post(`/api/v1/productTypes/active/${id}`)
}

export const updateProductType = async ({ id, description, businessId }) => {
  return await axios.put(`/api/v1/productTypes/${id}`, {
    description,
    businessId,
  })
}
