import axios from 'axios'

export const fetchProductLine = async ({ id }) => {
  return axios.get('/api/v1/productLines/getBusinessId/' + id)
}

export const createProductLine = async ({ businessId, name }) => {
  return axios.post('/api/v1/productLines', {
    businessId,
    description: name,
  })
}

export const deleteProductLine = async id => {
  return await axios.post(`/api/v1/productLines/deactive/${id}`)
}

export const activeProductLine = async id => {
  return await axios.post(`/api/v1/productLines/active/${id}`)
}

export const updateProductLine = async ({ id, description, businessId }) => {
  return await axios.put(`/api/v1/productLines/${id}`, {
    description,
    businessId,
  })
}
