import * as types from '../mutations-types/index.js'

export default {
  [types.FETCH_CURRENCIES_REQUEST] (state) {
    state.fetchingCurrencies = true
  },

  [types.FETCH_CURRENCIES_SUCCESS] (state, currencies) {
    state.currencies = currencies
    state.fetchingCurrencies = false
  },

  [types.FETCH_CURRENCIES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingCurrencies = false
  },

  [types.UPDATE_CURRENCIES_REQUEST] (state) {
    state.fetchingCurrencies = true
  },

  [types.UPDATE_CURRENCIES_SUCCESS] (state) {
    state.success = 'Se ha actualizado el valor de la moneda correctamente'
    state.fetchingCurrencies = false
  },

  [types.UPDATE_CURRENCIES_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingCurrencies = false
  },
}
