import {
  fetchParentsBusiness,
  fetchParentsBusinessById,
  fetchChildsBusiness,
  getBusinessChartData,
} from '../../api'
import * as types from '../mutations-types/index.js'

export default {
  async getParentsBusiness ({ commit }) {
    commit(types.FETCH_PARENTS_BUSINESS_REQUEST)
    const req = await fetchParentsBusiness()
    // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('businessPa', req)
    req.ok
      ? commit(types.FETCH_PARENTS_BUSINESS_SUCCESS, req.data.business)
      : commit(types.FETCH_PARENTS_BUSINESS_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getParentsBusinessById ({ commit }, payload) {
    commit(types.FETCH_PARENTS_BUSINESS_REQUEST)
    const req = await fetchParentsBusinessById(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('businessPa', req)
    req.ok
      ? commit(types.FETCH_PARENTS_BUSINESS_SUCCESS, req.data.business)
      : commit(types.FETCH_PARENTS_BUSINESS_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getChildsBusiness ({ commit }, payload) {
    commit(types.FETCH_CHILDS_BUSINESS_REQUEST)
    const req = await fetchChildsBusiness(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('businessChi', req)
    req.ok
      ? commit(types.FETCH_CHILDS_BUSINESS_SUCCESS, req.data.business)
      : commit(types.FETCH_CHILDS_BUSINESS_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getBusinessChartData ({ commit }, warehouseId) {
    commit(types.GET_BUSINESS_CHART_DATA_REQUEST)
    const req = await getBusinessChartData(warehouseId)
    req.ok
      ? commit(types.GET_BUSINESS_CHART_DATA_SUCCESS, req.data)
      : commit(types.GET_BUSINESS_CHART_DATA_FAILURE, req)
  },
}
