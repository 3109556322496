import * as types from '../mutations-types/index'

export default {
  [types.CREATE_PROVIDERS_REQUEST] (state) {
    state.fetchingProviders = true
  },
  [types.CREATE_PROVIDERS_SUCCESS] (state, data) {
    // state.providers = data
    state.success = 'Proveedor creado con exito!'
    state.fetchingProviders = false
  },
  [types.CREATE_PROVIDERS_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fetchingProviders = false
  },

  [types.UPDATE_PROVIDERS_REQUEST] (state) {
    state.fetchingProviders = true
  },
  [types.UPDATE_PROVIDERS_SUCCESS] (state, data) {
    // state.providers = data
    state.success = 'Proveedor actualizado con exito!'
    state.fetchingProviders = false
  },
  [types.UPDATE_PROVIDERS_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fetchingProviders = false
  },

  [types.FETCH_PROVIDERS_REQUEST] (state) {
    state.fetchingProviders = true
  },
  [types.FETCH_PROVIDERS_SUCCESS] (state, data) {
    // // // // // // // // // // // // // // console.log('MUTATION SEARCH', data)
    state.providers = data
    state.fetchingProviders = false
  },
  [types.FETCH_PROVIDERS_FAILURE] (state, error) {
    state.error = error.response?.data?.message
    state.fetchingProviders = false
  },
}
