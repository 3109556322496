import axios from 'axios'
import { TYPE_TRANSACTION } from '../../const'

export const fetchWarehouseOutputs = async ({ id }) => {
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.salida)
}

export const fetchWarehouseOutputsDetail = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const createWarhouseOutputs = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/transactions/output', payload)
}

export const deleteWarhouseOutputs = async id => {
  return await axios.post(`/api/v1/products/deactive/${id}`)
}

export const activeWarhouseOutputs = async id => {
  return await axios.post(`/api/v1/products/active/${id}`)
}

export const updateWarhouseOutputs = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
