import * as types from '../mutations-types'
import {
  fetchWarehousesTypes,
  createWarehousesTypes,
  deleteWarehouseType,
  activeWarehouseType,
  updateWarehouseType,
} from '@/api'

export default {
  async fetchWarehousesTypes ({ commit }, id) {
    commit(types.FETCH_WAREHOUSES_TYPES_REQUEST)
    const req = await fetchWarehousesTypes(id)
    req.ok
      ? commit(types.FETCH_WAREHOUSES_TYPES_SUCCESS, req.data.warehouseTypes)
      : commit(types.FETCH_WAREHOUSES_TYPES_FAILURE, req)
  },

  async createWarehousesTypes ({ commit }, { businessId, name }) {
    commit(types.CREATE_WAREHOUSES_TYPES_REQUEST)
    const req = await createWarehousesTypes(businessId, name)
    req.ok
      ? commit(types.CREATE_WAREHOUSES_TYPES_SUCCESS)
      : commit(types.CREATE_WAREHOUSES_TYPES_FAILURE, req)
  },

  async deleteWarehouseType ({ commit }, { id }) {
    commit(types.DELETE_WAREHOUSES_TYPES_REQUEST)
    const req = await deleteWarehouseType(id)
    req.ok
      ? commit(types.DELETE_WAREHOUSES_TYPES_SUCCESS)
      : commit(types.DELETE_WAREHOUSES_TYPES_FAILURE, req)
  },

  async activeWarehouseType ({ commit }, { id }) {
    commit(types.ACTIVE_WAREHOUSES_TYPES_REQUEST)
    const req = await activeWarehouseType(id)
    req.ok
      ? commit(types.ACTIVE_WAREHOUSES_TYPES_SUCCESS)
      : commit(types.ACTIVE_WAREHOUSES_TYPES_FAILURE, req)
  },

  async updateWarehouseType ({ commit }, { id, businessId, name }) {
    commit(types.UPDATE_WAREHOUSES_TYPES_REQUEST)
    const req = await updateWarehouseType(id, { businessId, name })
    req.ok
      ? commit(types.UPDATE_WAREHOUSES_TYPES_SUCCESS)
      : commit(types.UPDATE_WAREHOUSES_TYPES_FAILURE, req)
  },
}
