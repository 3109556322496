export const FETCH_WAREHOUSES_TYPES_REQUEST = 'FETCH_WAREHOUSES_TYPES_REQUEST'
export const FETCH_WAREHOUSES_TYPES_SUCCESS = 'FETCH_WAREHOUSES_TYPES_SUCCESS'
export const FETCH_WAREHOUSES_TYPES_FAILURE = 'FETCH_WAREHOUSES_TYPES_FAILURE'

export const CREATE_WAREHOUSES_TYPES_REQUEST = 'CREATE_WAREHOUSES_TYPES_REQUEST'
export const CREATE_WAREHOUSES_TYPES_SUCCESS = 'CREATE_WAREHOUSES_TYPES_SUCCESS'
export const CREATE_WAREHOUSES_TYPES_FAILURE = 'CREATE_WAREHOUSES_TYPES_FAILURE'

export const DELETE_WAREHOUSES_TYPES_REQUEST = 'DELETE_WAREHOUSES_TYPES_REQUEST'
export const DELETE_WAREHOUSES_TYPES_SUCCESS = 'DELETE_WAREHOUSES_TYPES_SUCCESS'
export const DELETE_WAREHOUSES_TYPES_FAILURE = 'DELETE_WAREHOUSES_TYPES_FAILURE'

export const ACTIVE_WAREHOUSES_TYPES_REQUEST = 'ACTIVE_WAREHOUSES_TYPES_REQUEST'
export const ACTIVE_WAREHOUSES_TYPES_SUCCESS = 'ACTIVE_WAREHOUSES_TYPES_SUCCESS'
export const ACTIVE_WAREHOUSES_TYPES_FAILURE = 'ACTIVE_WAREHOUSES_TYPES_FAILURE'

export const UPDATE_WAREHOUSES_TYPES_REQUEST = 'UPDATE_WAREHOUSES_TYPES_REQUEST'
export const UPDATE_WAREHOUSES_TYPES_SUCCESS = 'UPDATE_WAREHOUSES_TYPES_SUCCESS'
export const UPDATE_WAREHOUSES_TYPES_FAILURE = 'UPDATE_WAREHOUSES_TYPES_FAILURE'
