import axios from 'axios'
import { TYPE_TRANSACTION } from '../../const'

export const fetchBusinessSales = async ({ id, bussinessId }) => {
  // // // // // // // // // // // // // // // // // console.log(id, bussinessId)
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.venta_por_concesion + '&businessId=' + bussinessId)
}

export const fetchBusinessSalesNoApprove = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // console.log('por aqui')
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.venta_por_concesion + '&approved=0')
}

export const fetchWarehouseConcessionNoApprove = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // console.log('por aqui')
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.devolucion_por_concesion + '&approved=0')
}

export const fetchBusinessSalesDetail = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const fetchBusinessSalesPayments = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getPaymentMethodsByTransactionId/' + id)
}

export const fetchBusinessSalesSurplus = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getPaymentMethodSurplusByTransactionId/' + id)
}

export const createBusinessSales = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/transactions', payload)
}

export const deleteBusinessSalesByCode = async form => {
  return await axios.post('/api/v1/transactions/deactiveByCode', form)
}

export const aproveBusinessSalesByCode = async (form) => {
  // // // // // // // // // // // // // // // // // // // // // // // // console.log('formu', form)
  return await axios.post('/api/v1/transactions/approvedByCode', form)
}

export const updateBusinessSales = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
