import axios from 'axios'
import { TYPE_TRANSACTION } from '../../const'

export const fetchWarehouseMovements = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  // return axios.get('/api/v1/products/getBusinessId/' + id)
  return axios.get('/api/v1/transactions/getWarehousesId/' + id + '?typeTransactionId=' + TYPE_TRANSACTION.movimiento)
}

export const fetchWarehouseMovementsDetail = async ({ id }) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('id', id)
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const createWarhouseMovements = async (payload) => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('payload', payload)
  return axios.post('/api/v1/transactions/movement', payload)
}

export const deleteWarhouseMovements = async id => {
  return await axios.post(`/api/v1/products/deactive/${id}`)
}

export const activeWarhouseMovements = async id => {
  return await axios.post(`/api/v1/products/active/${id}`)
}

export const updateWarhouseMovements = async (payload) => {
  return await axios.put(`/api/v1/products/${payload.id}`, payload)
}
