export const FETCH_PRODUCT_CLASSIFICATION_REQUEST = 'FETCH_PRODUCT_CLASSIFICATION_REQUEST'
export const FETCH_PRODUCT_CLASSIFICATION_SUCCESS = 'FETCH_PRODUCT_CLASSIFICATION_SUCCESS'
export const FETCH_PRODUCT_CLASSIFICATION_FAILURE = 'FETCH_PRODUCT_CLASSIFICATION_FAILURE'

export const CREATE_PRODUCT_CLASSIFICATION_REQUEST = 'CREATE_PRODUCT_CLASSIFICATION_REQUEST'
export const CREATE_PRODUCT_CLASSIFICATION_SUCCESS = 'CREATE_PRODUCT_CLASSIFICATION_SUCCESS'
export const CREATE_PRODUCT_CLASSIFICATION_FAILURE = 'CREATE_PRODUCT_CLASSIFICATION_FAILURE'

export const DELETE_PRODUCT_CLASSIFICATION_REQUEST = 'DELETE_PRODUCT_CLASSIFICATION_REQUEST'
export const DELETE_PRODUCT_CLASSIFICATION_SUCCESS = 'DELETE_PRODUCT_CLASSIFICATION_SUCCESS'
export const DELETE_PRODUCT_CLASSIFICATION_FAILURE = 'DELETE_PRODUCT_CLASSIFICATION_FAILURE'

export const ACTIVE_PRODUCT_CLASSIFICATION_REQUEST = 'ACTIVE_PRODUCT_CLASSIFICATION_REQUEST'
export const ACTIVE_PRODUCT_CLASSIFICATION_SUCCESS = 'ACTIVE_PRODUCT_CLASSIFICATION_SUCCESS'
export const ACTIVE_PRODUCT_CLASSIFICATION_FAILURE = 'ACTIVE_PRODUCT_CLASSIFICATION_FAILURE'

export const UPDATE_PRODUCT_CLASSIFICATION_REQUEST = 'UPDATE_PRODUCT_CLASSIFICATION_REQUEST'
export const UPDATE_PRODUCT_CLASSIFICATION_SUCCESS = 'UPDATE_PRODUCT_CLASSIFICATION_SUCCESS'
export const UPDATE_PRODUCT_CLASSIFICATION_FAILURE = 'UPDATE_PRODUCT_CLASSIFICATION_FAILURE'
