import {
  fetchUser,
  fetchUsersBySucursalId,
  updateProfile,
  getRoles,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  enableUser,
  assignRoles,
  deassignRoles,
} from '../../api'
import * as types from '../mutations-types/index.js'

export default {
  async getUserByToken ({ commit }) {
    commit(types.FETCH_USER_REQUEST)
    const req = await fetchUser()
    req.ok
      ? commit(types.FETCH_USER_SUCCESS, req.data.user)
      : commit(types.FETCH_USER_FAILURE, { message: 'Código de error: ', error: req })
  },

  async getUsersBySucursalId ({ commit }, payload) {
    commit(types.FETCH_USER_BY_SUCURSAL_ID_REQUEST)
    const req = await fetchUsersBySucursalId(payload)
    // // // // // // // // // // // // // // console.log('REQ', req)
    req.ok
      ? commit(types.FETCH_USER_BY_SUCURSAL_ID_SUCCESS, req.data)
      : commit(types.FETCH_USER_BY_SUCURSAL_ID_FAILURE, { message: 'Código de error: ', error: req })
  },

  setUser ({ commit }, user) {
    commit(types.SET_USER, user)
  },

  async updateProfile ({ commit }, form) {
    commit(types.UPDATE_PROFILE_USER_REQUEST)
    const req = await updateProfile(form)
    req.ok
      ? commit(types.UPDATE_PROFILE_USER_SUCCESS, { name: form.name, lastName: form.lastName })
      : commit(types.UPDATE_PROFILE_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
  },

  async getRoles ({ commit }) {
    commit(types.ROLES_USERS_REQUEST)
    const req = await getRoles()
    req.ok
      ? commit(types.ROLES_USERS_SUCCESS, req.data)
      : commit(types.ROLES_USERS_FAILURE, { message: `Código de error: ${req.response.data.message}` })
  },

  async getUsers ({ commit }) {
    commit(types.FETCH_USERS_REQUEST)
    const req = await getUsers()
    // // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req)
    req.ok
      ? commit(types.FETCH_USERS_SUCCESS, req.data.users)
      : commit(types.FETCH_USERS_FAILURE, { message: `Código de error: ${req.response.data.message}` })
  },

  async createUser ({ commit, dispatch }, form) {
    commit(types.SET_USER_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('CREATE-USER', form)
    const req = await createUser(form)
    req.ok
      ? commit(types.SET_USER_SUCCESS, req.data.user)
      : commit(types.SET_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },

  async updateUser ({ commit, dispatch }, form) {
    commit(types.UPDATE_USER_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA', form)
    const req = await updateUser(form)
    req.ok
      ? commit(types.UPDATE_USER_SUCCESS, req.data.user)
      : commit(types.UPDATE_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },

  async deleteUser ({ commit, dispatch }, form) {
    commit(types.DELETE_USER_REQUEST)
    const req = await deleteUser(form)
    req.ok
      ? commit(types.DELETE_USER_SUCCESS, form)
      : commit(types.DELETE_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },

  async enableUser ({ commit, dispatch }, form) {
    commit(types.ENABLE_USER_REQUEST)
    const req = await enableUser(form)
    req.ok
      ? commit(types.ENABLE_USER_SUCCESS, form)
      : commit(types.ENABLE_USER_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },

  async assignRoles ({ commit, dispatch }, form) {
    commit(types.SET_AUTHORIZATIONS_REQUEST)
    const req = await assignRoles(form)
    req.ok
      ? commit(types.SET_AUTHORIZATIONS_SUCCESS, req.data.user)
      : commit(types.SET_AUTHORIZATIONS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },

  async deassignRoles ({ commit, dispatch }, form) {
    commit(types.UNSET_AUTHORIZATIONS_REQUEST)
    const req = await deassignRoles(form)
    req.ok
      ? commit(types.UNSET_AUTHORIZATIONS_SUCCESS, req.data.user)
      : commit(types.UNSET_AUTHORIZATIONS_FAILURE, { message: `Código de error: ${req.response.data.message}`, error: req })
    dispatch('getUsers')
  },
}
