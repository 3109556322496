export const FETCH_PRODUCT_LINE_REQUEST = 'FETCH_PRODUCT_LINE_REQUEST'
export const FETCH_PRODUCT_LINE_SUCCESS = 'FETCH_PRODUCT_LINE_SUCCESS'
export const FETCH_PRODUCT_LINE_FAILURE = 'FETCH_PRODUCT_LINE_FAILURE'

export const CREATE_PRODUCT_LINE_REQUEST = 'CREATE_PRODUCT_LINE_REQUEST'
export const CREATE_PRODUCT_LINE_SUCCESS = 'CREATE_PRODUCT_LINE_SUCCESS'
export const CREATE_PRODUCT_LINE_FAILURE = 'CREATE_PRODUCT_LINE_FAILURE'

export const DELETE_PRODUCT_LINE_REQUEST = 'DELETE_PRODUCT_LINE_REQUEST'
export const DELETE_PRODUCT_LINE_SUCCESS = 'DELETE_PRODUCT_LINE_SUCCESS'
export const DELETE_PRODUCT_LINE_FAILURE = 'DELETE_PRODUCT_LINE_FAILURE'

export const ACTIVE_PRODUCT_LINE_REQUEST = 'ACTIVE_PRODUCT_LINE_REQUEST'
export const ACTIVE_PRODUCT_LINE_SUCCESS = 'ACTIVE_PRODUCT_LINE_SUCCESS'
export const ACTIVE_PRODUCT_LINE_FAILURE = 'ACTIVE_PRODUCT_LINE_FAILURE'

export const UPDATE_PRODUCT_LINE_REQUEST = 'UPDATE_PRODUCT_LINE_REQUEST'
export const UPDATE_PRODUCT_LINE_SUCCESS = 'UPDATE_PRODUCT_LINE_SUCCESS'
export const UPDATE_PRODUCT_LINE_FAILURE = 'UPDATE_PRODUCT_LINE_FAILURE'
