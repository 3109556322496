import * as types from '../mutations-types/index.js'

export default {
  [types.FETCH_PARENTS_BUSINESS_REQUEST] (state) {
    state.fetchingBusiness = true
  },

  [types.FETCH_PARENTS_BUSINESS_SUCCESS] (state, business) {
    state.parentBusiness = business
    state.fetchingBusiness = false
  },

  [types.FETCH_PARENTS_BUSINESS_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingBusiness = false
  },

  [types.FETCH_PARENTS_BUSINESS_BY_ID_REQUEST] (state) {
    state.fetchingBusiness = true
  },

  [types.FETCH_PARENTS_BUSINESS_BY_ID_SUCCESS] (state, business) {
    state.parentBusiness = business
    state.fetchingBusiness = false
  },

  [types.FETCH_PARENTS_BUSINESS_BY_ID_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingBusiness = false
  },

  [types.FETCH_CHILDS_BUSINESS_REQUEST] (state) {
    state.fetchingBusiness = true
  },

  [types.FETCH_CHILDS_BUSINESS_SUCCESS] (state, business) {
    state.childsBusiness = business
    state.fetchingBusiness = false
  },

  [types.FETCH_CHILDS_BUSINESS_FAILURE] (state, { error, message }) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(error)
    state.error = message
    state.fetchingBusiness = false
  },

  [types.GET_BUSINESS_CHART_DATA_REQUEST] (state) {
    state.gettingBusinessChartData = true
  },

  [types.GET_BUSINESS_CHART_DATA_SUCCESS] (state, businessChartData) {
    state.gettingBusinessChartData = false
    state.businessChartData = businessChartData
  },

  [types.GET_BUSINESS_CHART_DATA_FAILURE] (state, error) {
    const errorMessage = error.response?.data?.message || 'Upps a ocurrido un error inesperado'
    state.error = errorMessage
    state.gettingBusinessChartData = false
  },
}
