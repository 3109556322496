export const FETCH_WAREHOUSE_CONCESSIONS_REQUEST = 'FETCH_WAREHOUSE_CONCESSIONS_REQUEST'
export const FETCH_WAREHOUSE_CONCESSIONS_SUCCESS = 'FETCH_WAREHOUSE_CONCESSIONS_SUCCESS'
export const FETCH_WAREHOUSE_CONCESSIONS_FAILURE = 'FETCH_WAREHOUSE_CONCESSIONS_FAILURE'

export const CHANGE_PARAMS = 'CHANGE_PARAMS'

export const FETCH_WAREHOUSE_CONCESSIONS_DETAIL_REQUEST = 'FETCH_WAREHOUSE_CONCESSIONS_DETAIL_REQUEST'
export const FETCH_WAREHOUSE_CONCESSIONS_DETAIL_SUCCESS = 'FETCH_WAREHOUSE_CONCESSIONS_DETAIL_SUCCESS'
export const FETCH_WAREHOUSE_CONCESSIONS_DETAIL_FAILURE = 'FETCH_WAREHOUSE_CONCESSIONS_DETAIL_FAILURE'

export const CREATE_WAREHOUSE_CONCESSIONS_REQUEST = 'CREATE_WAREHOUSE_CONCESSIONS_REQUEST'
export const CREATE_WAREHOUSE_CONCESSIONS_SUCCESS = 'CREATE_WAREHOUSE_CONCESSIONS_SUCCESS'
export const CREATE_WAREHOUSE_CONCESSIONS_FAILURE = 'CREATE_WAREHOUSE_CONCESSIONS_FAILURE'

export const DELETE_WAREHOUSE_CONCESSIONS_REQUEST = 'DELETE_WAREHOUSE_CONCESSIONS_REQUEST'
export const DELETE_WAREHOUSE_CONCESSIONS_SUCCESS = 'DELETE_WAREHOUSE_CONCESSIONS_SUCCESS'
export const DELETE_WAREHOUSE_CONCESSIONS_FAILURE = 'DELETE_WAREHOUSE_CONCESSIONS_FAILURE'

export const DELETE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST = 'DELETE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST'
export const DELETE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS = 'DELETE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS'
export const DELETE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE = 'DELETE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE'

export const APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_REQUEST = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_REQUEST'
export const APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_SUCCESS = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_SUCCESS'
export const APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_FAILURE = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_CODE_FAILURE'

export const APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_REQUEST'
export const APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_SUCCESS'
export const APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE = 'APPROVE_WAREHOUSE_CONCESSIONS_BY_ID_FAILURE'

export const UPDATE_WAREHOUSE_CONCESSIONS_REQUEST = 'UPDATE_WAREHOUSE_CONCESSIONS_REQUEST'
export const UPDATE_WAREHOUSE_CONCESSIONS_SUCCESS = 'UPDATE_WAREHOUSE_CONCESSIONS_SUCCESS'
export const UPDATE_WAREHOUSE_CONCESSIONS_FAILURE = 'UPDATE_WAREHOUSE_CONCESSIONS_FAILURE'
