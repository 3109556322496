import * as types from '../mutations-types'
import {
  fetchWarehouseOutputs,
  fetchWarehouseOutputsDetail,
  createWarhouseOutputs,
  deleteWarhouseOutputs,
  activeWarhouseOutputs,
  updateWarhouseOutputs,
} from '@/api'

export default {
  async fetchWarehouseOutputs ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_OUTPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarehouseOutputs(payload)
    // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_OUTPUTS_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_OUTPUTS_FAILURE, req)
  },

  async fetchWarehouseOutputsDetail ({ commit }, payload) {
    commit(types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('pay', payload)
    const req = await fetchWarehouseOutputsDetail(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_SUCCESS, req.data.transactionsWarehouses)
      : commit(types.FETCH_WAREHOUSE_OUTPUTS_DETAIL_FAILURE, req)
  },

  async createWarehouseOutputs ({ commit }, payload) {
    commit(types.CREATE_WAREHOUSE_OUTPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // console.log('createOutput', payload)
    const req = await createWarhouseOutputs(payload)
    req.ok
      ? commit(types.CREATE_WAREHOUSE_OUTPUTS_SUCCESS)
      : commit(types.CREATE_WAREHOUSE_OUTPUTS_FAILURE, req)
  },

  async deleteWarhouseOutputs ({ commit }, { id }) {
    commit(types.DELETE_WAREHOUSE_OUTPUTS_REQUEST)
    const req = await deleteWarhouseOutputs(id)
    req.ok
      ? commit(types.DELETE_WAREHOUSE_OUTPUTS_SUCCESS)
      : commit(types.DELETE_WAREHOUSE_OUTPUTS_FAILURE, req)
  },

  async activeWarhouseOutputs ({ commit }, { id }) {
    commit(types.ACTIVE_WAREHOUSE_OUTPUTS_REQUEST)
    const req = await activeWarhouseOutputs(id)
    req.ok
      ? commit(types.ACTIVE_WAREHOUSE_OUTPUTS_SUCCESS)
      : commit(types.ACTIVE_WAREHOUSE_OUTPUTS_FAILURE, req)
  },

  async updateWarhouseOutputs ({ commit }, payload) {
    commit(types.UPDATE_WAREHOUSE_OUTPUTS_REQUEST)
    // // // // // // // // // // // // // // // // // // // // // // // // console.log('update', payload)
    const req = await updateWarhouseOutputs(payload)
    req.ok
      ? commit(types.UPDATE_WAREHOUSE_OUTPUTS_SUCCESS)
      : commit(types.UPDATE_WAREHOUSE_OUTPUTS_FAILURE, req)
  },
}
