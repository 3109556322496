import * as types from '../mutations-types/index.js'

export default {
  [types.FETCH_WELCOME_REQUEST] (state) {
    state.fetchingWelcome = true
  },

  [types.FETCH_WELCOME_SUCCESS] (state, response) {
    state.fetchingWelcome = false
    state.welcome = response
  },

  [types.FETCH_WELCOME_FAILURE] (state, error) {
    state.fetchingWelcome = false
    state.error = error
  },

}
