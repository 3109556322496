import axios from 'axios'

export const fetchUser = async () => {
  return await axios.get('/api/v1/users/authenticate')
}

export const fetchUsersBySucursalId = async ({ id }) => {
  return await axios.get('/api/v1/users/getBySucursalId/' + id)
}

export const updateProfile = async (form) => {
  return await axios.put('/api/v1/users/edit_profile', form)
}

export const getRoles = async () => {
  return await axios.get('/api/v1/users/help/roles')
}

export const getUsers = async () => {
  return await axios.get('/api/v1/users/admin')
}

export const createUser = async form => {
  return await axios.post('/api/v1/users/admin', form)
}

export const updateUser = async form => {
  return await axios.put(`/api/v1/users/admin/${form.id}`, form)
}

export const deleteUser = async form => {
  return await axios.delete(`/api/v1/users/admin/${form.id}`)
}

export const enableUser = async form => {
  return await axios.put(`/api/v1/users/admin/active/${form.id}`)
}

export const assignRoles = async form => {
  // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA_ASSIGN', form)
  const data = {
    userId: form.userId,
    businessId: form.idBusiness,
    roleId: form.idRole,
  }
  // // // // // // // // // // // // // // // // // // // // // // console.log('DATA_ASSIGN', data)
  return await axios.post('/api/v1/authorizations/assign', data)
}

export const deassignRoles = async form => {
  return await axios.delete('/api/v1/authorizations/delete/' + form.id)
}
