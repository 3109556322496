export const SUPER_ADMIN = 1
export const ADMIN = 2
export const GENERAL_MANAGER = 3
export const STORE_MANAGER = 4
export const WAREHOUSE_MANAGER = 5
export const SALES_SUPERVISOR = 6
export const SELLER = 7

export const TYPE_TRANSACTION = {
  entrada: 1,
  salida: 2,
  movimiento: 3,
  concesion: 4,
  venta_por_concesion: 5,
  // venta: 6,
  devolucion_por_concesion: 7,
  devolucion_por_venta: 8,
}

export const PRINCIPAL_WAREHOUSE = 2
export const DIRECT_SALE = 4

export const PAYMENTS_METHODS = {
  efectivo: 1,
  pago_movil: 2,
  transferencia: 3,
  transferencia_internacional: 4,
  zelle: 5,
  credito: 6,
  punto_de_venta: 7,
  // pipol: 6,
  // paypal: 7,
}
