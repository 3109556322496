import * as types from '../mutations-types/index'

export default {
  setError ({ commit }, error) {
    commit(types.SET_ERROR, error)
  },

  setSuccess ({ commit }, success) {
    commit(types.SET_SUCCESS, success)
  },
}
