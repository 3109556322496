import * as types from '../mutations-types'

export default {
  [types.FETCH_PAYMENTS_METHODS_REQUEST] (state) {
    state.fetchingPaymentsMethods = true
  },

  [types.FETCH_PAYMENTS_METHODS_SUCCESS] (state, paymentMethods) {
    state.paymentMethods = paymentMethods
    state.fetchingPaymentsMethods = false
  },

  [types.FETCH_PAYMENTS_METHODS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingPaymentsMethods = false
  },
}
