export const FETCH_WAREHOUSES_REQUEST = 'FETCH_WAREHOUSES_REQUEST'
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS'
export const FETCH_WAREHOUSES_FAILURE = 'FETCH_WAREHOUSES_FAILURE'

export const FETCH_WAREHOUSES_BY_ID_REQUEST = 'FETCH_WAREHOUSES_BY_ID_REQUEST'
export const FETCH_WAREHOUSES_BY_ID_SUCCESS = 'FETCH_WAREHOUSES_BY_ID_SUCCESS'
export const FETCH_WAREHOUSES_BY_ID_FAILURE = 'FETCH_WAREHOUSES_BY_ID_FAILURE'

export const FETCH_STORE_WAREHOUSES_REQUEST = 'FETCH_STORE_WAREHOUSES_REQUEST'
export const FETCH_STORE_WAREHOUSES_SUCCESS = 'FETCH_STORE_WAREHOUSES_SUCCESS'
export const FETCH_STORE_WAREHOUSES_FAILURE = 'FETCH_STORE_WAREHOUSES_FAILURE'

export const SET_WAREHOUSES_REQUEST = 'SET_WAREHOUSES_REQUEST'
export const SET_WAREHOUSES_SUCCESS = 'SET_WAREHOUSES_SUCCESS'
export const SET_WAREHOUSES_FAILURE = 'SET_WAREHOUSES_FAILURE'

export const SET_BUSINESS_BY_WAREHOUSES_REQUEST = 'SET_BUSINESS_BY_WAREHOUSES_REQUEST'
export const SET_BUSINESS_BY_WAREHOUSES_SUCCESS = 'SET_BUSINESS_BY_WAREHOUSES_SUCCESS'
export const SET_BUSINESS_BY_WAREHOUSES_FAILURE = 'SET_BUSINESS_BY_WAREHOUSES_FAILURE'

export const CREATE_WAREHOUSES_REQUEST = 'CREATE_WAREHOUSES_REQUEST'
export const CREATE_WAREHOUSES_SUCCESS = 'CREATE_WAREHOUSES_SUCCESS'
export const CREATE_WAREHOUSES_FAILURE = 'CREATE_WAREHOUSES_FAILURE'

export const UPDATE_WAREHOUSES_REQUEST = 'UPDATE_WAREHOUSES_REQUEST'
export const UPDATE_WAREHOUSES_SUCCESS = 'UPDATE_WAREHOUSES_SUCCESS'
export const UPDATE_WAREHOUSES_FAILURE = 'UPDATE_WAREHOUSES_FAILURE'

export const DELETE_WAREHOUSES_REQUEST = 'DELETE_WAREHOUSES_REQUEST'
export const DELETE_WAREHOUSES_SUCCESS = 'DELETE_WAREHOUSES_SUCCESS'
export const DELETE_WAREHOUSES_FAILURE = 'DELETE_WAREHOUSES_FAILURE'

export const ACTIVATE_WAREHOUSES_REQUEST = 'ACTIVATE_WAREHOUSES_REQUEST'
export const ACTIVATE_WAREHOUSES_SUCCESS = 'ACTIVATE_WAREHOUSES_SUCCESS'
export const ACTIVATE_WAREHOUSES_FAILURE = 'ACTIVATE_WAREHOUSES_FAILURE'

export const DEACTIVATE_WAREHOUSES_REQUEST = 'DEACTIVATE_WAREHOUSES_REQUEST'
export const DEACTIVATE_WAREHOUSES_SUCCESS = 'DEACTIVATE_WAREHOUSES_SUCCESS'
export const DEACTIVATE_WAREHOUSES_FAILURE = 'DEACTIVATE_WAREHOUSES_FAILURE'

export const GET_WAREHOUSES_INPUT_CHART_DATA_REQUEST = 'GET_WAREHOUSES_INPUT_CHART_DATA_REQUEST'
export const GET_WAREHOUSES_INPUT_CHART_DATA_SUCCESS = 'GET_WAREHOUSES_INPUT_CHART_DATA_SUCCESS'
export const GET_WAREHOUSES_INPUT_CHART_DATA_FAILURE = 'GET_WAREHOUSES_INPUT_CHART_DATA_FAILURE'

export const GET_WAREHOUSES_OUTPUT_CHART_DATA_REQUEST = 'GET_WAREHOUSES_OUTPUT_CHART_DATA_REQUEST'
export const GET_WAREHOUSES_OUTPUT_CHART_DATA_SUCCESS = 'GET_WAREHOUSES_OUTPUT_CHART_DATA_SUCCESS'
export const GET_WAREHOUSES_OUTPUT_CHART_DATA_FAILURE = 'GET_WAREHOUSES_OUTPUT_CHART_DATA_FAILURE'

export const GET_WAREHOUSES_CONCESSIONED_CHART_DATA_REQUEST = 'GET_WAREHOUSES_CONCESSIONED_CHART_DATA_REQUEST'
export const GET_WAREHOUSES_CONCESSIONED_CHART_DATA_SUCCESS = 'GET_WAREHOUSES_CONCESSIONED_CHART_DATA_SUCCESS'
export const GET_WAREHOUSES_CONCESSIONED_CHART_DATA_FAILURE = 'GET_WAREHOUSES_CONCESSIONED_CHART_DATA_FAILURE'
