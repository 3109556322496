import * as types from '../mutations-types'
import {
  fetchProductCategory,
  createProductCategory,
  deleteProductCategory,
  activeProductCategory,
  updateProductCategory,
} from '@/api'

export default {
  async fetchProductCategory ({ commit }, payload) {
    commit(types.FETCH_PRODUCT_CATEGORY_REQUEST)
    const req = await fetchProductCategory(payload)
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log('reqpro', req)
    req.ok
      ? commit(types.FETCH_PRODUCT_CATEGORY_SUCCESS, req.data.productCategories)
      : commit(types.FETCH_PRODUCT_CATEGORY_FAILURE, req)
  },

  async createProductCategory ({ commit }, payload) {
    commit(types.CREATE_PRODUCT_CATEGORY_REQUEST)
    const req = await createProductCategory(payload)
    req.ok
      ? commit(types.CREATE_PRODUCT_CATEGORY_SUCCESS)
      : commit(types.CREATE_PRODUCT_CATEGORY_FAILURE, req)
  },

  async deleteProductCategory ({ commit }, { id }) {
    commit(types.DELETE_PRODUCT_CATEGORY_REQUEST)
    const req = await deleteProductCategory(id)
    req.ok
      ? commit(types.DELETE_PRODUCT_CATEGORY_SUCCESS)
      : commit(types.DELETE_PRODUCT_CATEGORY_FAILURE, req)
  },

  async activeProductCategory ({ commit }, { id }) {
    commit(types.ACTIVE_PRODUCT_CATEGORY_REQUEST)
    const req = await activeProductCategory(id)
    req.ok
      ? commit(types.ACTIVE_PRODUCT_CATEGORY_SUCCESS)
      : commit(types.ACTIVE_PRODUCT_CATEGORY_FAILURE, req)
  },

  async updateProductCategory ({ commit }, payload) {
    commit(types.UPDATE_PRODUCT_CATEGORY_REQUEST)
    const req = await updateProductCategory(payload)
    req.ok
      ? commit(types.UPDATE_PRODUCT_CATEGORY_SUCCESS)
      : commit(types.UPDATE_PRODUCT_CATEGORY_FAILURE, req)
  },
}
