import * as types from '../mutations-types'

export default {
  [types.FETCH_BUSINESS_SALES_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.FETCH_BUSINESS_SALES_SUCCESS] (state, businessSales) {
    state.businessSales = businessSales
    state.fetchingBusinessSales = false
  },

  [types.FETCH_BUSINESS_SALES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.FETCH_BUSINESS_SALES_DETAIL_REQUEST] (state) {
    state.fetchingBusinessSalesDetail = true
  },

  [types.FETCH_BUSINESS_SALES_DETAIL_SUCCESS] (state, warhouseConcessionsDetail) {
    state.warhouseConcessionsDetail = warhouseConcessionsDetail
    state.fetchingBusinessSalesDetail = false
  },

  [types.FETCH_BUSINESS_SALES_DETAIL_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingBusinessSalesDetail = false
  },

  [types.FETCH_BUSINESS_SALES_PAYMENTS_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.FETCH_BUSINESS_SALES_PAYMENTS_SUCCESS] (state, salePayments) {
    state.salePayments = salePayments
    state.fetchingBusinessSales = false
  },

  [types.FETCH_BUSINESS_SALES_PAYMENTS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.FETCH_BUSINESS_SALES_SURPLUS_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.FETCH_BUSINESS_SALES_SURPLUS_SUCCESS] (state, saleSurplus) {
    state.saleSurplus = saleSurplus
    state.fetchingBusinessSales = false
  },

  [types.FETCH_BUSINESS_SALES_SURPLUS_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response.data.message || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.CREATE_BUSINESS_SALES_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.CREATE_BUSINESS_SALES_SUCCESS] (state, payload) {
    state.success = 'Se ha realizado el presupuesto con exito'
    state.businessSalesResponse = payload
    state.fetchingBusinessSales = false
  },

  [types.CREATE_BUSINESS_SALES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.DELETE_BUSINESS_SALES_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.DELETE_BUSINESS_SALES_SUCCESS] (state) {
    state.success = 'Se ha eliminado correctamente la solicitud'
    state.fetchingBusinessSales = false
  },

  [types.DELETE_BUSINESS_SALES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.APPROVE_BUSINESS_SALES_BY_CODE_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.APPROVE_BUSINESS_SALES_BY_CODE_SUCCESS] (state) {
    state.success = 'Se ha aprobado correctamente la solicitud'
    state.fetchingBusinessSales = false
  },

  [types.APPROVE_BUSINESS_SALES_BY_CODE_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error || 'Error inesperado'
    state.fetchingBusinessSales = false
  },

  [types.UPDATE_BUSINESS_SALES_REQUEST] (state) {
    state.fetchingBusinessSales = true
  },

  [types.UPDATE_BUSINESS_SALES_SUCCESS] (state) {
    state.success = 'Se ha actualizado con exito el producto'
    state.fetchingBusinessSales = false
  },

  [types.UPDATE_BUSINESS_SALES_FAILURE] (state, error) {
    // // // // // // // // // console.error(error)
    state.error = error.response?.data?.message || 'Error inesperado'
    state.fetchingBusinessSales = false
  },
}
