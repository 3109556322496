import axios from 'axios'

export const fetchProductClassification = async ({ id }) => {
  return axios.get('/api/v1/productClassifications/getBusinessId/' + id)
}

export const createProductClassification = async ({ businessId, name }) => {
  return axios.post('/api/v1/productClassifications', {
    businessId,
    description: name,
  })
}

export const deleteProductClassification = async id => {
  return await axios.post(`/api/v1/productClassifications/deactive/${id}`)
}

export const activeProductClassification = async id => {
  return await axios.post(`/api/v1/productClassifications/active/${id}`)
}

export const updateProductClassification = async ({ id, description, businessId }) => {
  return await axios.put(`/api/v1/productClassifications/${id}`, {
    description,
    businessId,
  })
}
