import * as types from '../mutations-types/index.js'

export default {
  [types.LOGIN_REQUEST] (state) {
    state.fetchingLogin = true
  },

  [types.LOGIN_SUCCESS] (state, loginData) {
    state.fetchingLogin = false
    state.loginData = loginData
  },

  [types.LOGIN_FAILURE] (state, req) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req)
    state.fetchingLogin = false
    state.error = `Error al intentar realizar login, código de error: ${req.response.data.message}`
  },
}
