import axios from 'axios'

export const fetchReports = async (params) => {
  return axios.get('/api/v1/transactions/getByFilter/', {
    params,
  })
}

export const fetchReportsMaterial = async (params) => {
  return axios.get('/api/v1/transactions/getMaterialByFilter/', {
    params,
  })
}

export const fetchReportsMaterialType = async (params) => {
  return axios.get('/api/v1/transactions/getMaterialByTypeFilter/', {
    params,
  })
}

export const fetchReportsDetail = async ({ id }) => {
  return axios.get('/api/v1/transactions/getDetailByTransactionId/' + id)
}

export const fetchReportsCurrency = async (params) => {
  return axios.get('/api/v1/transactions/getTotalSaleBycurrencyId/', {
    params,
  })
}
