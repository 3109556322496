export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const FETCH_USER_BY_SUCURSAL_ID_REQUEST = 'FETCH_USER_BY_SUCURSAL_ID_REQUEST'
export const FETCH_USER_BY_SUCURSAL_ID_SUCCESS = 'FETCH_USER_BY_SUCURSAL_ID_SUCCESS'
export const FETCH_USER_BY_SUCURSAL_ID_FAILURE = 'FETCH_USER_BY_SUCURSAL_ID_FAILURE'

export const SET_USER = 'SET_USER'

export const UPDATE_PROFILE_USER_REQUEST = 'UPDATE_PROFILE_USER_REQUEST'
export const UPDATE_PROFILE_USER_SUCCESS = 'UPDATE_PROFILE_USER_SUCCESS'
export const UPDATE_PROFILE_USER_FAILURE = 'UPDATE_PROFILE_USER_FAILURE'

export const ROLES_USERS_REQUEST = 'ROLES_USERS_REQUEST'
export const ROLES_USERS_SUCCESS = 'ROLES_USERS_SUCCESS'
export const ROLES_USERS_FAILURE = 'ROLES_USERS_FAILURE'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const SET_USER_REQUEST = 'SET_USER_REQUEST'
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS'
export const SET_USER_FAILURE = 'SET_USER_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST'
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS'
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE'

export const SET_AUTHORIZATIONS_REQUEST = 'SET_AUTHORIZATIONS_REQUEST'
export const SET_AUTHORIZATIONS_SUCCESS = 'SET_AUTHORIZATIONS_SUCCESS'
export const SET_AUTHORIZATIONS_FAILURE = 'SET_AUTHORIZATIONS_FAILURE'

export const UNSET_AUTHORIZATIONS_REQUEST = 'UNSET_AUTHORIZATIONS_REQUEST'
export const UNSET_AUTHORIZATIONS_SUCCESS = 'UNSET_AUTHORIZATIONS_SUCCESS'
export const UNSET_AUTHORIZATIONS_FAILURE = 'UNSET_AUTHORIZATIONS_FAILURE'
