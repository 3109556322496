import * as types from '../mutations-types/index.js'

export default {
  [types.RECOVER_PASSWORD_REQUEST] (state) {
    state.fetchingLogin = true
  },

  [types.RECOVER_PASSWORD_SUCCESS] (state, loginData) {
    state.fetchingLogin = false
    state.success = 'Se ha enviado un correo con una clave temporal'
  },

  [types.RECOVER_PASSWORD_FAILURE] (state, req) {
    // // // // // // // // // // // // // // // // // // // // // // // // // console.log(req)
    state.fetchingLogin = false
    state.error = `Error al intentar realizar el envio de correo, código de error: ${req.response.data.message}`
  },
}
