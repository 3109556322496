export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST'
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE'

export const FETCH_REPORTS_DETAIL_REQUEST = 'FETCH_REPORTS_DETAIL_REQUEST'
export const FETCH_REPORTS_DETAIL_SUCCESS = 'FETCH_REPORTS_DETAIL_SUCCESS'
export const FETCH_REPORTS_DETAIL_FAILURE = 'FETCH_REPORTS_DETAIL_FAILURE'

export const FETCH_REPORTS_CURRENCY_REQUEST = 'FETCH_REPORTS_CURRENCY_REQUEST'
export const FETCH_REPORTS_CURRENCY_SUCCESS = 'FETCH_REPORTS_CURRENCY_SUCCESS'
export const FETCH_REPORTS_CURRENCY_FAILURE = 'FETCH_REPORTS_CURRENCY_FAILURE'
